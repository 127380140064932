import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

    if (measurementId) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: document.title,
      });
    } else {
      console.log("Pageview not sent: no Measurement ID provided");
    }
  }, [location]);

  return null;
};

export default TrackPageView;
