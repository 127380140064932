import axiosInstance from "../util/AxiosInstance/AxiosInstance";

export const sendContactForm = async (formData, captchaToken) => {
    try {
        const dataToSend = { ...formData, captchaToken }; // Include token

        const response = await axiosInstance.post('/contact/sendContactEmail', dataToSend);
        if (response.status === 200) {
            return { success: true, message: 'Message sent successfully!' };
        } else {
            return { success: false, message: 'Error sending message. Please try again.' };
        }
    } catch (error) {
        console.error("Error Sending Contact Form:", error.message);
        return { success: false, message: 'Error sending message. Please try again.' };
    }
};

