// RevolvingLogoComponent.js

import React from "react";
import styles from "./RevolvingLogoComponent.module.scss";
import { cardDetails } from "./components/cardDetails";
import CarouselItem from "./components/CarouselItem/CarouselItem";

const RevolvingLogoComponent = () => {
  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carouselTrack}>
        {cardDetails.map((item, index) => (
          <CarouselItem key={index} title={item.title} />
        ))}
        {/* Duplicate items for continuous loop effect */}
        {cardDetails.map((item, index) => (
          <CarouselItem key={`duplicate-${index}`} title={item.title} />
        ))}
      </div>
    </div>
  );
};

export default RevolvingLogoComponent;
