import React, { useEffect, useRef, useState } from "react";

// Import SCSS
import styles from "./ServiceLevelAgreementComponent.module.scss";

const ServiceLevelAgreementComponent = () => {
  const [activeSection, setActiveSection] = useState("");
  const sectionsRef = useRef([]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px 0px -90% 0px",
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    sectionsRef.current.forEach((section) => observer.observe(section));

    return () => {
      sectionsRef.current.forEach((section) => observer.unobserve(section));
    };
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className={styles.background}>
      {/* SEO Enhancements */}
      <meta
        name="description"
        content="WebNX Service Level Agreement (SLA) covers 100% network uptime, power guarantee, and hardware replacement policies. Learn more about our commitment to quality service."
      />
      <meta
        name="keywords"
        content="WebNX SLA, 100% uptime, power guarantee, hardware replacement policy, SLA credits"
      />

      <div className={styles.container}>
        {/* Main Content */}
        <div className={styles.col1}>
        <h1 className={styles.header}>Service Level Agreement</h1>
          <div
            ref={(el) => (sectionsRef.current[1] = el)}
            className={styles.section}
            id="introduction"
          >
            <h3>Introduction</h3>
            <p>
              We at WebNX prefer to keep our promises simple and straightforward.
              So we’ve tried to cut out the “legalese” and keep things as basic
              as possible.
            </p>
          </div>

          <div
            ref={(el) => (sectionsRef.current[2] = el)}
            className={styles.section}
            id="networkUptime"
          >
            <h3>Network Uptime</h3>
            <p>
              WebNX guarantees no less than 100% uptime per month. Outages in
              excess of 0% of the month will be credited one day for every 15
              minutes of downtime.
            </p>
          </div>

          <div
            ref={(el) => (sectionsRef.current[3] = el)}
            className={styles.section}
            id="powerGuarantee"
          >
            <h3>100% Power Guarantee</h3>
            <p>
              We guarantee that power will be uninterrupted throughout the
              month. This is backed up by UPS power along with multiple megawatt
              generators. Outages in excess of 0% will be credited one day for
              every 15 minutes of power loss.
            </p>
          </div>

          <div
            ref={(el) => (sectionsRef.current[4] = el)}
            className={styles.section}
            id="hardwareReplacement"
          >
            <h3>Hardware Replacement</h3>
            <p>
              Best efforts will be made to have the hardware replaced
              immediately, but it is guaranteed to be completed within two hours
              of confirmed failure. Should the original hardware not be
              replaceable, a secondary system will be built and provisioned
              within eight hours until proper replacement parts can be
              delivered. This guarantee does not cover data loss due to hardware
              failure. Clients are strongly encouraged to maintain regular,
              offsite backups.
            </p>
          </div>

          <div
            ref={(el) => (sectionsRef.current[5] = el)}
            className={styles.section}
            id="slaCreditRequests"
          >
            <h3>SLA Credit Requests</h3>
            <p>The following criteria must be met to qualify for SLA credit:</p>
            <ul>
              <li>
                Client must send in request for credit within 72 hours of
                outage.
              </li>
              <li>
                Client must have account maintained in good standing at the time
                of request.
              </li>
              <li>
                Client must not have had outstanding balances in excess of three
                business days in the last two billing cycles.
              </li>
              <li>
                Servers disconnected for nonpayment, abuse, or other reasons at
                the time of outage do not qualify for credit.
              </li>
              <li>Credit shall not exceed 100% of billing in a thirty-day cycle.</li>
              <li>
                Outages due to scheduled network or hardware maintenance are not
                eligible for SLA credit.
              </li>
              <li>
                Outages due to client misconfiguration of software are not
                eligible for SLA credit.
              </li>
              <li>
                Outages due to Force Majeure, including fire, earthquakes,
                riots, explosion, Sharknado, declared or undeclared wars,
                terrorist acts, insurrection, extraterrestrial invasion,
                injunction, or other event(s) outside the control of WebNX and
                its partners are not eligible for SLA credit.
              </li>
            </ul>
          </div>
        </div>

        {/* Sidebar Navigation */}
        <div className={styles.col2}>
          <ul className={styles.list}>
            <li
              className={`${styles.listItem} ${
                activeSection === "introduction" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("introduction")}
            >
              Introduction
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "networkUptime" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("networkUptime")}
            >
              Network Up-Time
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "powerGuarantee" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("powerGuarantee")}
            >
              100% Power Guarantee
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "hardwareReplacement" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("hardwareReplacement")}
            >
              Hardware Replacement
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "slaCreditRequests" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("slaCreditRequests")}
            >
              SLA Credit Requests
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServiceLevelAgreementComponent;
