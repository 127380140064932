// Import Libraries
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

// Import CSS
import "./App.css";
import "./scss/global.scss";

// Import Components
import Layout from "./Layout/Layout";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import HomePage from "./Pages/HomePage/HomePage";
import AllDedicatedServersPage from "./Pages/AllDedicatedServersPage/AllDedicatedServersPage";
import StandardDedicatedServersPage from "./Pages/DedicatedServersPage/DedicatedServersPage";
import WhoIsWebNXPage from "./Pages/WhoIsWebNXPage/WhoIsWebNXPage";
import DataCenterAndLocationsPage from "./Pages/DataCenterLocationsAndNetworksPage/DataCenterAndLocationsPage";
import ErrorBoundary from "./util/ErrorBoundary/ErrorBoundary";
import CustomDedicatedServersPage from "./Pages/CustomDedicatedServersPage/CustomDedicatedServersPage";
import GpuEnabledServersPage from "./Pages/GpuEnabledServersPage/GpuEnabledServersPage";
import StorageServerPage from "./Pages/StorageServerPage/StorageServerPage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import TermsOfServicePage from "./Pages/TermsOfService/TermsOfServicePage";
import ServiceLevelAgreementPage from "./Pages/ServiceLevelAgreementPage/ServiceLevelAgreementPage";
import ServerUpgradesPage from "./Pages/ServerUpgradesPage/ServerUpgradesPage";
import ColocationPage from "./Pages/ColocationPage/ColocationPage";
import TrackPageView from "./util/GoogleAnalytics/TrackPageView";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: (
      <Layout>
        <ErrorPage />
      </Layout>
    ),
    children: [
      ,
      { index: true, element: <HomePage /> },
      { path: "all-dedicated-servers", element: <AllDedicatedServersPage /> },
      {
        path: "standard-dedicated-servers",
        element: <StandardDedicatedServersPage />,
      },
      {
        path: "custom-dedicated-servers",
        element: <CustomDedicatedServersPage />,
      },
      { path: "gpu-enabled-servers", element: <GpuEnabledServersPage /> },
      { path: "storage-servers", element: <StorageServerPage /> },
      { path: "server-upgrades", element: <ServerUpgradesPage /> },
      { path: "about", element: <WhoIsWebNXPage /> },
      {
        path: "datacenter-locations-and-networks",
        element: <DataCenterAndLocationsPage />,
      },
      { path: "contact", element: <ContactPage /> },
      { path: "terms-of-service", element: <TermsOfServicePage /> },
      {
        path: "service-level-agreement",
        element: <ServiceLevelAgreementPage />,
      },
      { path: "colocation", element: <ColocationPage /> },
      { path: "error", element: <ErrorPage /> },
    ],
  },
]);

const App = () => {
  useEffect(() => {
    const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

    if (measurementId) {
      // Initialize Google Analytics if the Measurement ID exists
      ReactGA.initialize(measurementId);
      console.log("Google Analytics initialized");
    } else {
      console.log("Google Analytics not initialized: no Measurement ID provided");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>WebNX | Enterprise Internet Infrastructure Solutions</title>
        <meta
          name="description"
          content="WebNX offers reliable and high-performance dedicated servers, GPU-enabled servers, and storage solutions tailored to your business needs."
        />
        <meta
          name="keywords"
          content="dedicated servers, GPU-enabled servers, storage servers, custom servers, WebNX, server hosting"
        />
        <meta name="author" content="WebNX" />
        <meta
          property="og:title"
          content="WebNX | Enterprise Internet Infrastructure Solutions"
        />
        <meta
          property="og:description"
          content="Discover WebNX's reliable dedicated servers, GPU-enabled solutions, and scalable storage offerings. Experience 100% uptime and 24/7 support."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://webnx.com" />
        <meta
          property="og:image"
          content="https://webnx.com/images/webnx-og-image.jpg"
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://webnx.com" />
      </Helmet>
      <RouterProvider router={router}>
        <TrackPageView />
      </RouterProvider>
    </>
  );
};

export default App;
