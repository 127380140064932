// Import Libraries
import React from "react";

// Import Styles
import styles from "./Footer.module.scss";

// Import Assets
import Logo from "../../../Assets/logo.png";
import Location from "../../../Assets/icons/Location.svg";
import Email from "../../../Assets/icons/Email.svg";
import Call from "../../../Assets/icons/Call.svg";
import BottomFooter from "./Components/BottomFooter";

const Footer = () => {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.col1}>
          <img src={Logo} alt="WebNX Logo" />
          <p className={styles.text}>
            WebNX delivers reliable server hosting with 100% uptime,
            self-operated data centers, and 24/7 support. We offer flexible
            configurations and top-tier connectivity for secure, scalable
            solutions.
          </p>
        </div>
        
        <div className={styles.col2}>
          <h4>Fast Links</h4>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/standard-dedicated-servers">Dedicated Servers</a></li>
            <li><a href="/datacenter-locations-and-networks">Networks & Locations</a></li>
            <li><a href="/about">Company</a></li>
            <li><a href="/https://clients.webnx.com/index.php?rp=/login">Client Login</a></li>
            <li><a href="/service-level-agreement">Service Level Agreement</a></li>
            <li><a href="/terms-of-service">Terms of Service</a></li>
          </ul>
        </div>
        
        <div className={styles.col3}>
          <h4>Contact Info</h4>
          <div>
            <img src={Location} alt="Location Icon" />
            <p className={styles.contactInfoText}>119 North 600 West, Bldg 3B, Ogden, UT 84404</p>
          </div>
          <div>
            <img src={Call} alt="Call Icon" />
            <p className={styles.contactInfoText}>Main: 1-800-840-5996</p>
          </div>
          <div>
            <img src={Email} alt="Email Icon" />
            <p className={styles.contactInfoText}>support@webNX.com</p>
          </div>
        </div>
      </div>
      <BottomFooter />
    </div>
  );
};

export default Footer;
