import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import styles from './LoaderSpinner.modules.scss';

const LoaderSpinner = ({ loading }) => {
  return (
    <div className={styles.loaderContainer}>
        <TailSpin 
            height="50"
            width="50"
            color="#07587F"
            ariaLabel="loading"
            visible={loading}
        />
    </div>
  )
}

export default LoaderSpinner;