import axiosInstance from "../util/AxiosInstance/AxiosInstance";

export const getProducts = async () => {
    try {
        const response = await axiosInstance.post('/whmcs/getProducts');

        if (response.data.products) {
            return {
                result: response.data.result,
                totalResults: response.data.totalResults,
                products: response.data.products
            };
        } else {
            console.error("Unexpected Response Structure:   ", response.data);
            return null
        }
    }   catch (error) {
            console.error("Error Fetching Products: ", error.message);
            throw error;
    }
};

