// Import Libraries
import React from "react";
import { Helmet } from "react-helmet";

// Import Components
import HeroComponent from "../../Layout/Components/HeroComponent/HeroComponent";
import AboutUsComponent from "./AboutUsComponent/AboutUsComponent";
import WhyChooseUsComponent from "./WhyChooseUsComponent/WhyChooseUsComponent";

// Import Animations
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";

const WhoIsWebNXPage = () => {
  return (
    <div>
      {/* Helmet for SEO Metadata */}
      <Helmet>
        <title>Who Is WebNX? - About Us | WebNX</title>
        <meta
          name="description"
          content="Learn more about WebNX - A trusted provider of high-performance dedicated servers, colocation services, and custom IT solutions. Discover why businesses choose WebNX."
        />
        <meta
          name="keywords"
          content="WebNX, dedicated servers, colocation, high-performance servers, IT solutions, about WebNX"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webnx.com/about-us/who-is-webnx" />

        {/* Open Graph / Social Media Tags */}
        <meta property="og:title" content="Who Is WebNX? - About Us | WebNX" />
        <meta
          property="og:description"
          content="Discover WebNX, a leading provider of high-performance IT solutions, including dedicated servers, colocation, and more."
        />
        <meta property="og:url" content="https://webnx.com/about-us/who-is-webnx" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://webnx.com/assets/og-image.jpg" /* Add a valid image URL */
        />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Who Is WebNX? - About Us | WebNX" />
        <meta
          name="twitter:description"
          content="Learn more about WebNX and its high-performance IT solutions tailored for businesses of all sizes."
        />
        <meta
          name="twitter:image"
          content="https://webnx.com/assets/twitter-image.jpg" /* Add a valid image URL */
        />

        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "WebNX",
            url: "https://webnx.com",
            logo: "https://webnx.com/assets/logo.png", /* Add your logo URL */
            description:
              "WebNX provides high-performance dedicated servers, colocation services, and custom IT solutions for businesses worldwide.",
            sameAs: [
              "https://www.facebook.com/webnx",
              "https://www.twitter.com/webnx",
              "https://www.linkedin.com/company/webnx",
            ],
          })}
        </script>
      </Helmet>

      {/* Hero Section */}
      <HeroComponent
        title="About Us"
        subtitle="Who Is WebNX?"
        showButton1={false}
        showButton2={false}
      />

      {/* About Us Section */}
      <FadeInOnScroll>
        <AboutUsComponent />
      </FadeInOnScroll>

      {/* Why Choose Us Section */}
      <FadeInOnScroll>
        <WhyChooseUsComponent />
      </FadeInOnScroll>
    </div>
  );
};

export default WhoIsWebNXPage;
