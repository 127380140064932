import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./DedicatedServerLocations.module.scss";

// Import Assets
import Utah from "../../../../Assets/icons/Utah.svg";
import NewYork from "../../../../Assets/icons/NewYork.svg";
import California from "../../../../Assets/icons/California.svg";

const DedicatedServerLocations = () => {
  const [selectedLocation, setSelectedLocation] = useState("california");

  // Define location names based on selected location
  const locationNames = {
    california: "Los Angeles, CA",
    utah: "Ogden, UT",
    newyork: "Manhattan, NY",
  };

  // Define animation variants for content transitions
  const contentVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 20 },
  };

  return (
    <div className={styles.background}>
      <div className={styles.dedicatedServersContainer}>
        <div className={styles.dedicatedServersLeft}>
          <h1 className={styles.dedicatedServersHeader}>
            WebNX Dedicated <br /> Server Locations
          </h1>
          <h2 className={styles.dedicatedServersSubHeader}>
            {locationNames[selectedLocation]}
          </h2>

          {/* Animated content based on selected location */}
          <AnimatePresence mode="wait">
            {selectedLocation === "california" && (
              <motion.div
                key="california"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={contentVariants}
                transition={{ duration: 0.5 }}
                className={styles.dedicatedServersText}
              >
                <p>
                  Looking for new Los Angeles Dedicated Servers? Do you need a fast
                  connection to the Asia-Pacific region? Look no further. All WebNX
                  Los Angeles Dedicated Servers utilize the WebNX High-Performance
                  BGP Route Optimized network. Beware of providers offering the buzz
                  phrase “Asia optimized” networks. Some providers just claim it
                  because they are on the West Coast, while others only do light
                  route changes.
                </p>
                <p>
                  The WebNX High-Performance BGP Route Optimized network offers the
                  best possible routing to all locations. By using the Noction IRP
                  and direct uplinks to 11 different carriers, we offer real-time
                  BGP route optimization to all our clients.
                </p>
                <a
                  className={styles.dedicatedServersButton}
                  href="/datacenter-locations-and-networks"
                  aria-label="Learn more about Los Angeles, CA Dedicated Server Locations"
                >
                  Learn More
                </a>
              </motion.div>
            )}

            {selectedLocation === "utah" && (
              <motion.div
                key="utah"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={contentVariants}
                transition={{ duration: 0.5 }}
                className={styles.dedicatedServersText}
              >
                <p>
                  Are you looking for a new, low-cost, dedicated server? Do you need
                  a fast and reliable internet connection at an affordable price?
                  Look no further. The WebNX Ogden Utah Dedicated Servers offer a
                  terrific blend of performance and value. All WebNX Utah Dedicated
                  Servers utilize our High-Performance BGP Route Optimized network.
                </p>
                <p>
                  By using the Noction IRP and direct uplinks to 6 different
                  carriers, we offer real-time BGP route optimization to all our
                  clients. We optimize our paths continually to provide the best
                  available routes.
                </p>
                <a
                  className={styles.dedicatedServersButton}
                  href="/datacenter-locations-and-networks"
                  aria-label="Learn more about Ogden, UT Dedicated Server Locations"
                >
                  Learn More
                </a>
              </motion.div>
            )}

            {selectedLocation === "newyork" && (
              <motion.div
                key="newyork"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={contentVariants}
                transition={{ duration: 0.5 }}
                className={styles.dedicatedServersText}
              >
                <p>
                  Are you looking for new East Coast dedicated servers? Do you need
                  a fast and reliable internet connection in the heart of Manhattan,
                  NYC? WebNX offers enterprise-quality hardware and network at
                  affordable prices. The WebNX NYC Dedicated Servers offer a
                  terrific blend of performance and value.
                </p>
                <p>
                  All NYC Dedicated Servers utilize the WebNX High-Performance BGP
                  Route Optimized Network. We use the Noction IRP and direct uplinks
                  to 3 different carriers to offer real-time BGP route optimization
                  to all our clients.
                </p>
                <a
                  className={styles.dedicatedServersButton}
                  href="/datacenter-locations-and-networks"
                  aria-label="Learn more about Manhattan, NY Dedicated Server Locations"
                >
                  Learn More
                </a>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Location Selection Images */}
        <div className={styles.statesContainer}>
          <motion.img
            onClick={() => setSelectedLocation("california")}
            src={California}
            alt="Los Angeles, California Dedicated Server Location"
            className={
              selectedLocation === "california" ? `${styles.activeImage}` : ""
            }
            animate={
              selectedLocation === "california" ? { scale: 1.1, rotate: 3 } : {}
            }
            whileHover={{ scale: 1.1, rotate: 3 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 500, damping: 50 }}
          />

          <motion.img
            onClick={() => setSelectedLocation("utah")}
            src={Utah}
            alt="Ogden, Utah Dedicated Server Location"
            className={selectedLocation === "utah" ? `${styles.activeImage}` : ""}
            animate={
              selectedLocation === "utah" ? { scale: 1.1, rotate: 3 } : {}
            }
            whileHover={{ scale: 1.1, rotate: 3 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 500, damping: 50 }}
          />

          <motion.img
            onClick={() => setSelectedLocation("newyork")}
            src={NewYork}
            alt="Manhattan, New York Dedicated Server Location"
            className={
              selectedLocation === "newyork" ? `${styles.activeImage}` : ""
            }
            animate={
              selectedLocation === "newyork" ? { scale: 1.1, rotate: 3 } : {}
            }
            whileHover={{ scale: 1.1, rotate: 3 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 500, damping: 50 }}
          />
        </div>
      </div>
    </div>
  );
};

export default DedicatedServerLocations;
