// Import Libraries
import React from 'react';

// Import SCSS
import styles from './WhyChooseUsComponent.module.scss';

const WhyChooseUsComponent = () => {
  return (
    <section className={styles.background} aria-labelledby="why-choose-us">
      {/* SEO Metadata */}
      <meta
        name="description"
        content="Discover why businesses choose WebNX for hosting solutions. Custom-built solutions, high-performance networks, and 24/7/365 support make us stand out."
      />
      <div className={styles.container}>
        {/* Column 1: Introduction */}
        <div className={styles.col1}>
          <div className={styles.col1Content}>
            <h1 id="why-choose-us" className={styles.header}>
              Why Choose <br /> Us?
            </h1>
            <h2 className={styles.subHeader}>WebNX Guarantee</h2>
            <p className={styles.text}>
              WebNX goes beyond standard hosting by offering custom-built solutions tailored to your unique needs. Here’s why clients choose us.
            </p>
          </div>
        </div>

        {/* Column 2: Features List */}
        <div className={styles.col2}>
          <div className={styles.itemContainer}>
            {[
              { number: "1", text: "Custom Solutions" },
              { number: "2", text: "High Performance Networks" },
              { number: "3", text: "24/7/365 Support" },
              { number: "4", text: "State of the Art Datacenters" },
              { number: "5", text: "Scalable Solutions" },
              { number: "6", text: "Proven Reliability" },
            ].map((item, index) => (
              <div key={index} className={styles.item}>
                <h3 aria-label={`Feature ${item.number}`} className={styles.number}>
                  {item.number}
                </h3>
                <p className={styles.feature}>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsComponent;
