// Import Libraries
import React, { useEffect, useState, useRef } from "react";

// Import SCSS
import styles from "./TermsOfServiceComponent.module.scss";

const TermsOfServiceComponent = () => {
  const [activeSection, setActiveSection] = useState("");
  const sectionsRef = useRef([]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px 0px -80% 0px",
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    sectionsRef.current.forEach((section) => observer.observe(section));

    return () => {
      sectionsRef.current.forEach((section) => observer.unobserve(section));
    };
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.col1}>
          <h1 className={styles.header}>
            Acceptable Usage Policy & Terms Of Service
          </h1>
          <div
            ref={(el) => (sectionsRef.current[0] = el)}
            className={styles.section}
            id="introduction"
          >
            <h3>Introduction</h3>
            <p>
              This agreement governs the use of all WebNX’s services, except as
              otherwise agreed in writing. Violation of this agreement may
              result in suspension or termination of your service. In the event
              of a dispute between you and WebNX regarding the interpretation of
              this agreement, WebNX’s interpretation shall govern. If you have
              any questions regarding this agreement contact the WebNX Billing
              Department. Use of WebNX’s service constitutes acceptance of this
              agreement.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[1] = el)}
            className={styles.section}
            id="offensiveContent"
          >
            <h3>Offensive Content</h3>
            <p>
              You may not host, publish, distribute, transfer or transmit any
              information via WebNX’s service that WebNX believes:
            </p>
            <ul>
              <li>
                Is unfair or deceptive under the consumer protection laws of any
                jurisdiction, including but not limited to chain letters and
                pyramid schemes.
              </li>
              <li>Is defamatory or violates a person’s privacy.</li>
              <li>
                Creates a risk to a person’s safety or health, creates a risk to
                public safety or health, compromises national security, or
                interferes with an investigation by any law enforcement agency.
              </li>
              <li>
                Improperly exposes trade secrets or other confidential or
                proprietary information of another person.
              </li>
              <li>
                Is intended to assist others in defeating technical copyright
                protection.
              </li>
              <li>
                Clearly infringes on another person’s trade or service mark,
                patent, or other property right.
              </li>
              <li>
                Promotes the use, trafficking, purchasing, and/or sale of
                illegal drugs, violates export control laws, relates to illegal
                gambling, or illegal arms trafficking.
              </li>
              <li>
                Is otherwise illegal or solicits conduct that is illegal under
                laws applicable to you or to WebNX.
              </li>
              <li>
                Is otherwise malicious, fraudulent, or may result in retaliation
                against WebNX by offended viewers.
              </li>
            </ul>
            <p className={styles.marginTop}>
              Violation of any of this section will result in a fee of at least
              $50.00 and you may face suspension, termination, and other
              applicable charges.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[2] = el)}
            className={styles.section}
            id="security"
          >
            <h3>Security</h3>
            <p>
              You must take all security precautions necessary to ensure the
              security of your server and our network. WebNX is not responsible
              for the security of your server and if WebNX believes your server
              to be compromised your server may be disconnected without notice
              and possibly formatted. You must protect the confidentiality of
              your passwords and change them periodically.
            </p>
            <p>
              Violation of any of this section will result in a fee of at least
              $50.00 and you may face suspension, termination, and other
              applicable charges.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[3] = el)}
            className={styles.section}
            id="bulkCommercialEmail(UCE)"
          >
            <h3>Bulk Commercial Email (UCE)</h3>
            <p>
              You must obtain WebNX’s advance approval for any bulk commercial
              e-mail, which will not be given unless you are able to demonstrate
              all of the following to WebNX’s reasonable satisfaction:
            </p>
            <ul>
              <li>
                Your intended recipients have given their consent to receive
                e-mail via some affirmative means, such as an opt-in procedure.
              </li>
              <li>
                Your procedures for soliciting consent include reasonable means
                to ensure that the person giving consent is the owner of the
                e-mail address for which the consent is given.
              </li>
              <li>
                You retain evidence of the recipient’s consent in a form that
                may be promptly produced on request, and you honor recipient’s
                and WebNX’s requests to produce consent evidence within 24 hours
                of receipt of the request.
              </li>
              <li>
                The body of the e-mail must describe how the e-mail address was
                obtained, for example, “You opted in to receive this e-mail
                promotion from our Web site or from one of our partner sites,”
                and information on how to request evidence of the consent, for
                example, “If you would like to learn more about how we received
                your e-mail address please contact us at abuse@webnx.com.”
              </li>
              <li>
                You have procedures in place that allow a recipient to easily
                revoke their consent – such as a link in the body of the e-mail,
                or instructions to reply with the word “Remove” in the subject
                line. Revocations of consent are honored within 24 hours, and
                you notify recipients that their revocation of their consent
                will be honored in 24 hours.
              </li>
              <li>
                You must post an abuse@webnx.com e-mail address on the first
                page of any Web site associated with the e-mail, you must
                register that address at abuse.net, and you must promptly
                respond to messages sent to that address.
              </li>
              <li>
                You must have a Privacy Policy posted for each domain associated
                with the mailing.
              </li>
              <li>You have the means to track anonymous complaints.</li>
              <li>
                You may not obscure the source of your e-mail in any manner.
                Your e-mail must include the recipient’s e-mail address in the
                body of the message or in the “TO” line of the e-mail.
              </li>
              <li>
                You otherwise comply with the CAN-SPAM Act and other applicable
                law.
              </li>
            </ul>
            <p className={styles.marginTop}>
              These policies apply to messages sent using your WebNX service, or
              to messages sent from any network by you or any person on your
              behalf that directly or indirectly refer the recipient to a site
              hosted via your WebNX service. In addition, you may not use a
              third party e-mail service that does not practice similar
              procedures for all its customers. WebNX may test and otherwise
              monitor your compliance with its requirements, including
              requesting opt-in information from a random sample of your list at
              any time. Violation of any of this section will result in a fee of
              at least $150.00 and you may face suspension, termination, and
              other applicable charges.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[4] = el)}
            className={styles.section}
            id="unsolicitedEmail"
          >
            <h3>Unsolicited E-Mail</h3>
            <p>
              You may not send any unsolicited e-mail, either in bulk or
              individually, to any person who has indicated that they do not
              wish to receive it or has not opted to receive it. You must comply
              with the rules of any other network you access or participate in
              using your WebNX’s services. Violation of any of this section will
              result in a fee of at least $350.00 and you may face suspension,
              termination, and other applicable charges.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[5] = el)}
            className={styles.section}
            id="SpamVertisingEmail"
          >
            <h3>Spamvertising E-Mail</h3>
            <p>
              You may not post any unsolicited e-mail to any newsgroups, online
              forums, or interactive web sites using your WebNX’s services such
              as e-mail addresses or directing people to your WebNX’s web site
              account. You must comply with the rules of any other network you
              access or participate in using your WebNX’s services. Violation of
              any of this section will result in a fee of at least $100.00 and
              you may face suspension, termination, and other applicable
              charges.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[6] = el)}
            className={styles.section}
            id="materialProtectedByCopyright"
          >
            <h3>Material Protected by Copyright</h3>
            <p>
              You may not publish, distribute, or otherwise copy in any manner
              any music, software, art, or other work protected by copyright law
              unless:
            </p>
            <ul>
              <li>
                You have been expressly authorized by the owner of the copyright
                for the work to copy the work in that manner;
              </li>
              <li>
                You are otherwise permitted by established United States
                copyright law to copy the work in that manner.
              </li>
            </ul>
            <p className={styles.marginTop}>
              WebNX will terminate the service of copyright infringes without
              notice. Violation of any of this section will result in a fee of
              at least $500.00 and you may face suspension, termination, and
              other applicable charges.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[7] = el)}
            className={styles.section}
            id="copyrightInfringementNotice"
          >
            <h3>
              Copyright Infringement Notice (Digital Millennium Copyright Act)
            </h3>
            <p>
              If you believe your copyright is being infringed by a person using
              the WebNX network, please send an appropriate DMCA notice to:
            </p>
            <p>Your notice must include the following:</p>
            <ul>
              <li>
                A physical or electronic signature of a person authorized to act
                on behalf of the owner of an exclusive right that is allegedly
                infringed;
              </li>
              <li>
                Identification of the copyrighted work claimed to have been
                infringed, or if multiple copyrighted works at a single site are
                covered by a single notification, a representative list of such
                works at that site;
              </li>
              <li>
                Identification of the material that is claimed to be infringing
                or to be the subject of infringing activity and that is to be
                removed or access to which is to be disabled, and information
                reasonably sufficient to permit WebNX to locate the material;
              </li>
              <li>
                Information reasonably sufficient to permit WebNX to contact
                you, such as an address, telephone number, and if available, an
                e-mail address;
              </li>
              <li>
                A statement that you have a good faith belief that use of the
                material in the manner complained of is not authorized by the
                copyright owner, the copyright owner’s agent, or the law;
              </li>
              <li>
                A statement that the information in the notification is
                accurate, and under penalty of perjury, that you are authorized
                to act on behalf of the owner of an exclusive right that is
                allegedly infringed.
              </li>
            </ul>
          </div>
          <div
            ref={(el) => (sectionsRef.current[8] = el)}
            className={styles.section}
            id="other"
          >
            <h3>Other</h3>
            <p>
              You must have valid and current information on file with WebNX at
              all times. Failure to do so will result in immediate suspension or
              termination of your account.
            </p>
            <p>
              Updates can be carried out via{" "}
              <a href="https://clients.webnx.com">https://clients.webnx.com</a>.
              Should you be unable to access this, please send an email to{" "}
              <a href="mailto:support@webnx.com">support@webnx.com</a>.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[9] = el)}
            className={styles.section}
            id="internetAbuse"
          >
            <h3>Internet Abuse</h3>
            <p>
              You may not engage in illegal, abusive, or irresponsible behavior,
              including:
            </p>
            <ul>
              <li>
                Unauthorized access to or use of data, systems, or networks,
                including any attempt to probe, scan, or test the vulnerability
                of a system or network or to breach security or authentication
                measures (including those belonging to WebNX and its customers)
                without express authorization of the owner of the system or
                network;
              </li>
              <li>
                Monitoring data or traffic on any network or system without the
                authorization of the owner of the system or network;
              </li>
              <li>
                Interference with service to any user, host, or network
                including, without limitation, mail bombing, flooding,
                deliberate attempts to overload a system, and broadcast attacks;
              </li>
              <li>
                Use of an Internet account or computer without the owner’s
                authorization, including, but not limited to Internet scanning
                (tricking other people into releasing their passwords), password
                robbery, security hole scanning, and port scanning;
              </li>
              <li>
                Forging of any TCP-IP packet header or any part of the header
                information in an e-mail or a newsgroup posting;
              </li>
              <li>
                Any conduct that is likely to result in retaliation against the
                WebNX network;
              </li>
              <li>
                Use of WebNX’s network in a way that unreasonably interferes
                with WebNX’s other customers' use of the network.
              </li>
            </ul>
            <p className={styles.marginTop}>
              Violation of any of this section will result in a fee of at least
              $100.00 and you may face suspension, termination, and other
              applicable charges.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[10] = el)}
            className={styles.section}
            id="newsgroupChatForums"
          >
            <h3>Newsgroup, Chat Forums, Other Networks</h3>
            <p>
              You must comply with the rules and conventions for postings to any
              bulletin board, chat group, or other forum in which you
              participate, such as IRC and USENET groups, including their rules
              for content and commercial postings. These groups usually prohibit
              the posting of off-topic commercial messages or mass postings to
              multiple forums.
            </p>
            <p className={styles.marginTop}>
              IRC Networks are strictly prohibited from our network.
            </p>
            <p className={styles.marginTop}>
              Violation of any of this section will result in a fee of at least
              $100.00, and you may face suspension, termination, and other
              applicable charges.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[11] = el)}
            className={styles.section}
            id="paymentPolicies"
          >
            <h3>Payment Policies</h3>
            <p>
              WebNX provides service through subscriptions. In the event you
              cancel a subscription, you will need to rectify the situation
              immediately, or you may face account suspension or termination.
              All payments are due by 5:00 PM EST on the date of their renewal.
              Failure to pay at this time will result in suspension or
              termination of your account, and you may be subject to
              disconnection or reconnection fees.
            </p>
            <p>
              By accepting this agreement, you understand that WebNX reserves
              the right to charge your credit card for any charges applied to
              your account. You understand bandwidth overages will be billed at
              the rate of $0.50 per GB.
            </p>
            <p>
              If for any reason you file a chargeback or a dispute with PayPal,
              WebNX reserves the right to apply an administrative fee of not
              less than $75.00 but no more than $150.00. You understand that in
              the event an invoice becomes past due, all servers on your account
              will be suspended, and you will be charged a $75.00 disconnection
              fee per server.
            </p>
            <p>
              You understand that in the event you do not pay an invoice
              generated by WebNX, WebNX has the right to send your account to a
              collection agency. All payments to WebNX are non-refundable. In
              the event this happens, you will be responsible for paying all
              collection, legal, and attorney costs. A charge of not less than
              $75.00 but not more than $150.00 will also be charged to your
              account.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[12] = el)}
            className={styles.section}
            id="cancellations"
          >
            <h3>Cancellations</h3>
            <p>
              Cancellations will only be accepted via our cancellation request
              option in your Client Portal. Cancellations that are not made via
              the form will be ignored and will not be processed. In order for
              your cancellation to be successful, you must receive confirmation
              from WebNX. If you do not receive confirmation from WebNX, do not
              assume your account is being canceled.
            </p>
            <p>
              You are then required to submit your cancellation in writing if
              you do not receive a response via e-mail. All cancellations must
              be made one (1) calendar day prior to the next invoice being due.
              Any cancellations made after this are not valid and will not be
              prorated.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[13] = el)}
            className={styles.section}
            id="agreement"
          >
            <h3>Agreement</h3>
            <p>
              Use of our service in any way constitutes acceptance of this
              agreement. This agreement may be terminated by the subscriber, in
              writing, thirty days in advance of server billing dates.
              Termination of this agreement must be made in writing to the
              address provided to you in your billing area.
            </p>
            <p>
              You understand that terminating this agreement may result in
              administrative charges, and you accept any charge that may result
              from that. WebNX reserves the right, as the provider, to terminate
              this agreement at any time, without prior notice.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[14] = el)}
            className={styles.section}
            id="disclaimerOfWarranties"
          >
            <h3>Disclaimer of Warranties</h3>
            <p>
              WebNX makes no warranty of our services either expressed or
              implied. It is the customer’s responsibility to have backups of
              their data at all times. WebNX has the right to restrict access to
              any server, possibly causing you to not have access to your files.
              You understand that WebNX is not required to allow access to any
              server on their network. WebNX cannot be held responsible for
              lost, corrupt, or restricted data for any reason.
            </p>
            <p>
              WebNX is under no duty, and does not by this AUP undertake a duty,
              to monitor or police our customers’ activities and disclaims any
              responsibility for any misuse of the WebNX network.
            </p>
            <p className={styles.marginTop}>
              Inquiries regarding this policy should be directed to the WebNX
              abuse department at{" "}
              <a href="mailto:abuse@webnx.com">abuse@webnx.com</a>
            </p>
            <p className={styles.marginTop}>
              <span>
                YOU AGREE THAT YOUR USE OF WEBNX’S SERVICES IS AT YOUR SOLE
                RISK.
              </span>{" "}
              BECAUSE OF THE NUMBER OF POSSIBLE SOURCES OF INFORMATION AVAILABLE
              THROUGH THE WEBNX WEB SERVICES, AND THE INHERENT HAZARDS AND
              UNCERTAINTIES OF ELECTRONIC DISTRIBUTION, THERE MAY BE
              INTERRUPTIONS, DELAYS, OMISSIONS, INACCURACIES, OR OTHER PROBLEMS
              WITH THIS INFORMATION. IF YOU RELY ON THE WEBNX WEB SERVICES OR
              ANY MATERIAL AVAILABLE THROUGH THEM, YOU DO SO AT YOUR OWN RISK.
              YOU UNDERSTAND THAT YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO
              YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM ANY
              MATERIAL AND/OR DATA DOWNLOADED FROM OR OTHERWISE PROVIDED THROUGH
              THE WEBNX WEB SERVICES.
            </p>
            <p className={styles.marginTop}>
              <span>
                THE WEBNX SERVICES ARE PROVIDED TO YOU “AS IS,” “WITH ALL
                FAULTS,” AND “AS AVAILABLE.”
              </span>{" "}
              WEBNX AND ITS AGENTS AND LICENSORS CANNOT AND DO NOT WARRANT THE
              ACCURACY, COMPLETENESS, USEFULNESS, TIMELINESS, NONINFRINGEMENT,
              MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE OF THE
              INFORMATION AVAILABLE THROUGH THE WEBNX WEB SERVICES, NOR DO THEY
              GUARANTEE THAT THE WEBNX WEB SERVICES WILL BE ERROR-FREE, OR
              CONTINUOUSLY AVAILABLE, OR THAT THE WEBNX WEB SERVICES WILL BE
              FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[15] = el)}
            className={styles.section}
            id="limitationOfLiability"
          >
            <h3>Limitation of Liability</h3>
            <p>
              The Parties shall only be liable for the contract value upon
              breach of Contract for Non-performance.
            </p>
            <p className={styles.marginTop}>
              <span>
                UNDER NO CIRCUMSTANCES SHALL WEBNX (INCLUDING ITS PARENTS,
                SUBSIDIARIES, AND AFFILIATES) OR ITS AGENTS OR LICENSORS BE
                LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES ARISING OUT OF ANY
                USE OR MISUSE OF THE WEBNX WEB SERVICES, INCLUDING, WITHOUT
                LIMITATION, LIABILITY FOR CONSEQUENTIAL, SPECIAL, INCIDENTAL,
                INDIRECT, OR SIMILAR DAMAGES, EVEN IF ADVISED BEFOREHAND OF THE
                POSSIBILITY OF THESE DAMAGES, REGARDLESS OF THE FORM OR CAUSE OF
                ACTION INCLUDING, BUT NOT LIMITED TO, CONTRACT, NEGLIGENCE, AND
                OTHER TORT ACTIONS.
              </span>
            </p>
            <p className={styles.marginTop}>
              Because some states do not allow the exclusion or limitation of
              certain categories of damages, the above limitation may not apply
              to you. In these states, the liability of WebNX and its agents and
              licensors is limited to the fullest extent permitted by applicable
              state law.
            </p>
            <p className={styles.marginTop}>
              <span>
                YOU AGREE THAT THE LIABILITY OF WEBNX (INCLUDING ITS PARENTS,
                SUBSIDIARIES, AND AFFILIATES) AND ITS AGENTS AND LICENSORS, IF
                ANY, ARISING OUT OF ANY KIND OF LEGAL CLAIM IN ANY WAY CONNECTED
                TO THE WEBNX WEB SERVICES SHALL NOT EXCEED THE AMOUNT YOU PAID
                TO WEBNX FOR THE USE OF THE WEBNX WEB SERVICES.
              </span>
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[16] = el)}
            className={styles.section}
            id="indemnification"
          >
            <h3>Indemnification</h3>
            <p>
              You agree to indemnify, defend, and hold harmless WEBNX (including
              its parents, subsidiaries, and affiliates and all of their
              respective officers, directors, employees, agents, licensors,
              suppliers, and any third-party information providers) against all
              claims, losses, expenses, damages, and costs (including reasonable
              attorney fees) resulting from any breach of the Terms or use of
              the WEBNX Web Services.
            </p>
            <p className={styles.marginTop}>
              WEBNX reserves the right, at its election, to assume the exclusive
              defense and control of any matter subject to indemnification by
              you, and you agree to cooperate with WEBNX in connection with our
              defense.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[17] = el)}
            className={styles.section}
            id="governingLaw"
          >
            <h3>Governing Law</h3>
            <p>
              This Contract shall be construed in accordance with the laws of
              the State of California.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[18] = el)}
            className={styles.section}
            id="arbitration"
          >
            <h3>Arbitration</h3>
            <p>
              Any controversies or disputes arising out of or relating to this
              Contract shall be resolved by binding arbitration in accordance
              with the then-current Commercial Arbitration Rules of the American
              Arbitration Association. The parties shall select a mutually
              acceptable arbitrator knowledgeable about issues relating to the
              subject matter of this Contract.
            </p>
            <p>
              In the event the parties are unable to agree to such a selection,
              each party will select an arbitrator, and the two arbitrators in
              turn shall select a third arbitrator, all three of whom shall
              preside jointly over the matter. The arbitration shall take place
              at a location that is reasonably centrally located between the
              parties, or otherwise mutually agreed upon by the parties.
            </p>
            <p>
              All documents, materials, and information in the possession of
              each party that are in any way relevant to the dispute shall be
              made available to the other party for review and copying no later
              than 30 days after the notice of arbitration is served. The
              arbitrator(s) shall not have the authority to modify any provision
              of this Contract or to award punitive damages.
            </p>
            <p>
              The arbitrator(s) shall have the power to issue mandatory orders
              and restraint orders in connection with the arbitration. The
              decision rendered by the arbitrator(s) shall be final and binding
              on the parties, and judgment may be entered in conformity with the
              decision in any California court having jurisdiction. The
              agreement to arbitration shall be specifically enforceable under
              the prevailing arbitration law.
            </p>
            <p className={styles.marginTop}>
              During the continuance of any arbitration proceeding, the parties
              shall continue to perform their respective obligations under this
              Contract.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[19] = el)}
            className={styles.section}
            id="notice"
          >
            <h3>Notice</h3>
            <p>
              Any notice or communication required or permitted under this
              Contract shall be sufficiently given if delivered in person or by
              certified mail, return receipt requested, to the address set forth
              in the opening paragraph or to such other address as one party may
              have furnished to the other in writing.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[20] = el)}
            className={styles.section}
            id="waiverOfContractualRight"
          >
            <h3>Waiver of Contractual Right</h3>
            <p>
              The failure of either party to enforce any provision of this
              Contract shall not be construed as a waiver or limitation of that
              party’s right to subsequently enforce and compel strict compliance
              with every provision of this Contract.
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[21] = el)}
            className={styles.section}
            id="constructionAndInterpretation"
          >
            <h3>Construction and Interpretation</h3>
            <p>
              The rule requiring construction or interpretation against the
              drafter is waived. The document shall be deemed as if it were
              drafted by both parties in a mutual effort.
            </p>
          </div>
        </div>
        <div className={styles.col2}>
          <ul className={styles.list}>
            <li
              className={`${styles.listItem} ${
                activeSection === "introduction" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("introduction")}
            >
              Introduction
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "offensiveContent" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("offensiveContent")}
            >
              Offensive Content
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "security" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("security")}
            >
              Security
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "bulkCommercialEmail(UCE)"
                  ? styles.active
                  : ""
              }`}
              onClick={() => scrollToSection("bulkCommercialEmail(UCE)")}
            >
              Bulk Commercial Email (UCE)
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "unsolicitedEmail" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("unsolicitedEmail")}
            >
              Unsolicited Email
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "SpamVertisingEmail" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("SpamVertisingEmail")}
            >
              Spamvertising Email
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "materialProtectedByCopyright"
                  ? styles.active
                  : ""
              }`}
              onClick={() => scrollToSection("materialProtectedByCopyright")}
            >
              Material Protected by Copyright
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "copyrightInfringementNotice"
                  ? styles.active
                  : ""
              }`}
              onClick={() => scrollToSection("copyrightInfringementNotice")}
            >
              Copyright Infringement Notice
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "other" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("other")}
            >
              Other
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "internetAbuse" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("internetAbuse")}
            >
              Internet Abuse
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "newsgroupChatForums" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("newsgroupChatForums")}
            >
              Newsgroup, Chat Forums, Other Networks
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "paymentPolicies" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("paymentPolicies")}
            >
              Payment Policies
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "cancellations" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("cancellations")}
            >
              Cancellations
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "agreement" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("agreement")}
            >
              Agreement
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "disclaimerOfWarranties" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("disclaimerOfWarranties")}
            >
              Disclaimer of Warranties
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "limitationOfLiability" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("limitationOfLiability")}
            >
              Limitation of Liability
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "indemnification" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("indemnification")}
            >
              Indemnification
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "governingLaw" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("governingLaw")}
            >
              Governing Law
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "arbitration" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("arbitration")}
            >
              Arbitration
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "notice" ? styles.active : ""
              }`}
              onClick={() => scrollToSection("notice")}
            >
              Notice
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "waiverOfContractualRight"
                  ? styles.active
                  : ""
              }`}
              onClick={() => scrollToSection("waiverOfContractualRight")}
            >
              Waiver of Contractual Right
            </li>
            <li
              className={`${styles.listItem} ${
                activeSection === "constructionAndInterpretation"
                  ? styles.active
                  : ""
              }`}
              onClick={() => scrollToSection("constructionAndInterpretation")}
            >
              Construction and Interpretation
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsOfServiceComponent;
