// Import Libraries
import React, { useState } from "react";
import Lottie from "react-lottie";

// Import Functions
import { sendContactForm } from "../../../../ApiLayer/contactForm";

// Import SCSS
import styles from "./ContactFormComponent.module.scss";

// Import Assets
import successAnimationData from "../../../../Assets/video/Confetti.json";

// Import Components
import CaptchaModalComponent from "../../../../Components/CaptchaModalComponent/CaptchaModalComponent";

const ContactFormComponent = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [statusMessage, setStatusMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);


  // Lottie Animation Options
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Handle Input Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Basic Validation
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
      isValid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address.";
      isValid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleCaptchaVerification = async (token) => {
    setShowCaptchaModal(false);

    if (!token) {
        setIsError(true);
        setStatusMessage("Captcha verification failed.");
        return;
    }

    const response = await sendContactForm(formData, token); // Pass token explicitly
    if (response.success) {
        setIsError(false);
        setStatusMessage("Your message was sent successfully!");
        setShowSuccessAnimation(true);
        setTimeout(() => setShowSuccessAnimation(false), 3000);
    } else {
        setIsError(true);
        setStatusMessage("Failed to send your message. Please try again.");
    }
    setFormData({ name: "", email: "", message: "" });
    setErrors({});
};



  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
        setIsError(true);
        setStatusMessage("Please fill out all required fields correctly.");
        return;
    }
    setShowCaptchaModal(true);
};

  return (
    <div className={styles.col1}>
      <h1 className={styles.header}>Contact Sales Department</h1>
      <p className={styles.text}>
        Connect with our Sales Department to explore customized server solutions
        that meet your exact specifications. Whether you need unique
        configurations or have specific performance goals, we're here to help
        find the perfect fit for your business needs.
      </p>
      {statusMessage && (
        <p className={isError ? styles.errorText : styles.successText}>
          {statusMessage}
        </p>
      )}
      {showSuccessAnimation && (
        <div className={styles.lottieTopRight}>
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      )}
      <form className={styles.contactForm} onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          {errors.name && (
            <span className={styles.errorText}>{errors.name}</span>
          )}
        </div>
        <div className={styles.formGroup}>
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errors.email && (
            <span className={styles.errorText}>{errors.email}</span>
          )}
        </div>
        <div className={styles.formGroup2}>
          <textarea
            name="message"
            placeholder="Your Message"
            rows="4"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          {errors.message && (
            <span className={styles.errorText}>{errors.message}</span>
          )}
        </div>
        <button type="submit" className={styles.sendButton}>
          Send Message
        </button>
      </form>
      <CaptchaModalComponent
                isOpen={showCaptchaModal}
                onClose={() => setShowCaptchaModal(false)}
                onVerify={handleCaptchaVerification}
            />
    </div>
  );
};

export default ContactFormComponent;
