import React, { useEffect } from 'react';
import styles from './CaptchaModalComponent.module.scss';

const CaptchaModalComponent = ({ isOpen, onClose, onVerify }) => {
  useEffect(() => {
      if (isOpen) {
          window.grecaptcha.enterprise.ready(() => {
              window.grecaptcha.enterprise.execute(process.env.REACT_APP_CAPTCHA_SITE_KEY, { action: 'submit' })
                  .then((token) => {
                      if (onVerify) onVerify(token); // Pass token to parent
                  })
                  .catch((err) => {
                      console.error("Error executing reCAPTCHA:", err);
                  });
          });
      }
  }, [isOpen, onVerify]);

  if (!isOpen) return null;

  return (
      <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
              <button className={styles.closeButton} onClick={onClose}>
                  &times;
              </button>
              <p>Verifying CAPTCHA...</p>
          </div>
      </div>
  );
};

export default CaptchaModalComponent;
