import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

// Component Imports
import TopHeader from "./Components/TopHeader/TopHeader";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import CallToAction from "./Components/CallToAction/CallToAction";

// Import Styles
import styles from './Layout.module.scss';

const Layout = (props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className={styles.layoutContainer}>
      <Header
        isMobileMenuOpen={isMobileMenuOpen}
        toggleMobileMenu={toggleMobileMenu}
      />

      {/* Dark overlay with transition, shown when mobile menu is open */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            className={styles.overlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          />
        )}
      </AnimatePresence>

      <main>{props.children ?? <Outlet />}</main>
      <CallToAction />
      <Footer />
    </div>
  );
};

export default Layout;
