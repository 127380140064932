// Import Libraries
import React from 'react';

// Import SCSS
import styles from './AboutUsComponent.module.scss';

// Import Assets
import AboutUsBubble from '../../../Assets/AboutUsBubble.svg';

const AboutUsComponent = () => {
  return (
    <section className={styles.background} aria-labelledby="about-us">
      {/* SEO Metadata */}
      <meta
        name="description"
        content="Learn about WebNX - Established in 1999, providing exceptional web hosting, dedicated servers, and colocation services with outstanding customer support and uptime."
      />
      <div className={styles.container}>
        {/* Content Column */}
        <div className={styles.col1}>
          <h1 id="about-us" className={styles.header}>
            About Us
          </h1>
          <h2 className={styles.subHeader}>Our Mission</h2>
          <p className={styles.text}>
            WebNX was founded in 1999 with a simple mission: to deliver exceptional web hosting paired with outstanding customer support and unbeatable uptime.
            <br />
            <br />
            Over the years, we’ve grown to offer a wide range of services, including mid to high-end dedicated servers and colocation, all backed by flexible network and bandwidth options. Our relatively small, hard-working team is dedicated to ensuring every customer receives personalized attention and reliable service.
            <br />
            <br />
            With data centers in Ogden, Los Angeles, and Manhattan, we’re equipped to meet the needs of businesses across the country. Give us a try, and we’re confident you’ll be thrilled with what WebNX has to offer.
          </p>
        </div>
        {/* Image Column */}
        <div className={styles.col2}>
          <img
            src={AboutUsBubble}
            alt="Illustration representing WebNX's mission and services"
            loading="lazy"
            width="400"
            height="300"
          />
        </div>
      </div>
    </section>
  );
};

export default AboutUsComponent;
