import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './ImageComponent.module.scss';

const ImageComponent = ({ images = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(1); // Track slide direction (1 for next, -1 for prev)

  useEffect(() => {
    setCurrentIndex(0); // Reset currentIndex to zero when images array changes
  }, [images]);

  const handleNext = () => {
    setDirection(1); // Set direction to forward
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setDirection(-1); // Set direction to backward
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const getVisibleImages = () => {
    if (!images || images.length === 0) return [];
    return [
      images[currentIndex],
      images[(currentIndex + 1) % images.length],
      images[(currentIndex + 2) % images.length],
    ];
  };

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <AnimatePresence initial={false} custom={direction}>
          {getVisibleImages().map((image, index) => (
            <motion.div
              key={`${currentIndex}-${index}-${image}`}
              className={styles.gridItem}
              initial={{ x: direction === 1 ? 100 : -100 }} // Slide in from the right or left
              animate={{ x: 0 }} // Center position
              exit={{ x: direction === 1 ? -100 : 100 }} // Slide out to the left or right
              transition={{ duration: 0.5 }}
            >
              <img src={image} alt={`Datacenter ${index + 1}`} className={styles.image} />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      {/* Navigation Arrows */}
      <button onClick={handlePrev} className={styles.arrowLeft}>‹</button>
      <button onClick={handleNext} className={styles.arrowRight}>›</button>
    </div>
  );
};

export default ImageComponent;
