import React from 'react';
import styles from './ErrorPage.module.scss';

const ErrorPage = ({ error }) => {
  return (
    <div className={styles.background}>
    <div className={styles.container}>
      <div className={styles.errorPage}>
        <h1>404</h1>
        <h2>Something went wrong.</h2>
        {error && (
          <div className={styles.errorDetails}>
            <p><strong>Error:</strong> {error.message}</p>
          </div>
        )}
        <p>Please try reloading the page or contact support if the issue persists.</p>
        <a href="/">Back Home</a>
      </div>
    </div>
    </div>
  );
};

export default ErrorPage;
