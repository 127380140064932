import React from 'react';

import styles from './BottomFooter.module.scss';

const BottomFooter = () => {
  return (
    <div className={styles.bottomFooter}>
        <p>Copyright @2024 All rights reserved | This template is made by WebNX Inc</p>
    </div>
  )
}

export default BottomFooter