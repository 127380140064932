import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const fadeInVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const FadeInOnScroll = ({ children }) => {
  const [ref, inView] = useInView({ threshold: 0.2 });
  const [disableAnimation, setDisableAnimation] = useState(false);

  // Detect screen size and disable animation for small screens
  useEffect(() => {
    const handleResize = () => {
      setDisableAnimation(window.innerWidth < 700);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (disableAnimation) {
    // Render children without motion on small screens
    return <div ref={ref}>{children}</div>;
  }

  return (
    <motion.div
      ref={ref}
      variants={fadeInVariant}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
    >
      {children}
    </motion.div>
  );
};

export default FadeInOnScroll;
