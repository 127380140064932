// HomePage.js
import React from "react";
import { Helmet } from "react-helmet";

// Import other components
import HeroComponent from "../../Layout/Components/HeroComponent/HeroComponent";
import OurServicesComponent from "./Components/OurServicesComponent.js/OurServicesComponent";
import FeaturedSolutionsComponent from "./Components/FeaturedSolutionsComponent.js/FeaturedSolutionsComponent";
import WhyChooseWebNX from "./Components/WhyChooseWebNXComponent/WhyChooseWebNX";

// Import Animation HOC
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";
const HomePage = () => {
  return (
    <div>
      <Helmet>
        <title>
          High-Performance Dedicated Servers | GPU, Storage & Custom Solutions
          by WebNX
        </title>
        <meta
          name="description"
          content="WebNX provides enterprise-grade internet infrastructure with high-performance dedicated servers, GPU-enabled servers, scalable storage solutions, and customizable hardware for diverse business needs."
        />
        <meta
          name="keywords"
          content="enterprise internet infrastructure, high-performance dedicated servers, GPU-enabled servers, storage solutions, custom server hosting, WebNX data centers"
        />
        <meta
          property="og:title"
          content="WebNX | High-Performance Dedicated Servers and Hosting Solutions"
        />
        <meta
          property="og:description"
          content="Discover WebNX's enterprise solutions: dedicated servers, GPU-enabled servers, scalable storage, and custom configurations for optimized performance."
        />
        <meta property="og:url" content="https://webnx.com" />
        <meta
          property="og:image"
          content="https://webnx.com/assets/hero-image.jpg" // <!-- Replace with the actual path to your hero image -->
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webnx.com" />
      </Helmet>

      <HeroComponent
        title="Welcome to WebNX"
        subtitle="Enterprise Internet <br /> Infrastructure at Affordable <br />  Prices"
        showButton1={true}
        buttonText1="Shop Servers"
        buttonLink1="/standard-dedicated-servers#filterComponent"
      />

      <FadeInOnScroll>
        <FeaturedSolutionsComponent />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <OurServicesComponent />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <WhyChooseWebNX />
      </FadeInOnScroll>
    </div>
  );
};

export default HomePage;
