// CarouselItem.js

import React from "react";
import styles from "./CarouselItem.module.scss";

const CarouselItem = ({ title }) => {
  return (
      <p className={styles.carouselText}>{title}</p>
  );
};

export default CarouselItem;
