import Logo from '../../../../../Assets/logo.png';

export const cardDetails = [
    { title: "China Unicom" },
    { title: "Level3" },
    { title: "Verizon" },
    { title: "NTT" },
    { title: "PCCW Global" },
    { title: "Century Link" },
    { title: "Any2 Peering Fabric" },
    { title: "APAC ISP’s" },
    { title: "China Telecom" },
    { title: "Comcast" },
    { title: "Zayo" },
    { title: "TATA" },
    { title: "Sparkle" },
    { title: "Telia" },
    { title: "GTT" },
    { title: "Equinix Peering" },
    { title: "Google Peering" },
    { title: "Hurricane Electric" },
    { title: "Cogent" },
    { title: "Noction IRP" },
];
