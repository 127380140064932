// Import Libraries
import React from 'react';

// Import SCSS
import styles from './CallToAction.module.scss';

// Import Assets
import Video from '../../../Assets/video/server_room.mp4';
import CallToActionImage from '../../../Assets/call_to_action_optimized.webp';

const CallToAction = () => {
  return (
    <div className={styles.container}>
        {/* <video className={styles.video} src={Video} autoPlay loop muted playsInline/> */}
        <img className={styles.image} src={CallToActionImage} alt="" />
        <div className={styles.overlay}></div>
        <div className={styles.content}>
            <h1>Unleash The Power Of WebNX</h1>
            <p>Get started with powerful, reliable solutions tailored to your needs.</p>
            <a href="/standard-dedicated-servers" className="cta-button">Browse Servers</a>
        </div>
    </div>
  )
}

export default CallToAction