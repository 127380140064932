// Import Libraries
import React from "react";
import { Helmet } from "react-helmet";

// Import Components
import HeroComponent from "../../Layout/Components/HeroComponent/HeroComponent";
import CustomServerQuoteForm from "./Components/CustomServerQuoteForm/CustomServerQuoteForm";
import CustomServerStandardsComponent from "../DedicatedServersPage/Components/CustomServerStandardsComponent/CustomServerStandardsComponent";
import DedicatedServerLocations from "../DedicatedServersPage/Components/DedicatedServerLocations/DedicatedServerLocations";
import TestNetworkSpeedsComponent from "../DedicatedServersPage/Components/TestNetworkSpeedsComponent/TestNetworkSpeedsComponent";
import ServerPageIntro from "../../Components/ServerPageIntro/ServerPageIntro";

// Import Assets
import CustomServer from "../../Assets/Custom_Server.png";

// Import Animation
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";

const CustomDedicatedServersPage = () => {
  return (
    <div>
      {/* Helmet for Metadata */}
      <Helmet>
        <title>
          Custom Dedicated Servers | Tailored Server Solutions by WebNX
        </title>
        <meta
          name="description"
          content="WebNX Custom Dedicated Servers are tailored to fit every unique business need. Enjoy high-compute power, GPU integration, and premium hardware with global connectivity."
        />
        <meta
          name="keywords"
          content="custom dedicated servers, tailored server solutions, GPU integration, high-compute servers, WebNX hosting"
        />
        <meta property="og:title" content="Custom Dedicated Servers | WebNX" />
        <meta
          property="og:description"
          content="Discover WebNX Custom Dedicated Servers for tailored solutions with high-compute power, GPU capabilities, and a global optimized network."
        />
        <meta
          property="og:image"
          content="https://webnx.com/assets/custom-server.jpg"
        />
        <meta
          property="og:url"
          content="https://webnx.com/custom-dedicated-servers"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://webnx.com/custom-dedicated-servers"
        />
      </Helmet>

      {/* Hero Section */}
      <HeroComponent
        title="Custom Dedicated Servers"
        subtitle="Custom Solutions For Every Need. <br /> Unleash the Power of <br /> WebNX"
        buttonText1="Shop Servers Now"
        buttonLink1="#filterComponent"
        showButton1={true}
        buttonText2="More Information"
        buttonLink2="#moreInfo"
        showButton2={true}
      />

      {/* Custom Server Quote Form */}
      <CustomServerQuoteForm />

      {/* Server Page Intro */}
      <FadeInOnScroll>
        <ServerPageIntro
          header="WebNX Custom <br />Dedicated Servers"
          subheader="Tailored Solutions to Fit Every Unique Need"
          text="In a world where technology evolves rapidly, WebNX is here to build
            servers that meet your exact specifications. We understand that
            sometimes standard configurations just don't cut it. That's why
            WebNX offers a range of custom-built Dedicated Servers, meticulously
            crafted to meet the unique needs of each client. No matter the
            complexity of your requirements, we can create a solution tailored
            precisely to you. Need high-compute power? We offer single, dual,
            and even quad CPU setups to handle any workload. Seeking a server
            with GPU capabilities? We can integrate specialized graphics cards
            for tasks like AI, rendering, or scientific calculations. With
            WebNX, there's no limit to what you can customize. <br /> <br />
            At WebNX, we don't just assemble hardware - we engineer performance.
            Every custom server is built with premium components from top-tier
            brands like SuperMicro, Intel, AMD, and LSI. Whether you need
            CentOS, Debian, Ubuntu Server, or Windows Server, we support a wide
            array of operating systems to ensure compatibility with your
            application requirements. Our High-Performance BGP Route Optimized
            network guarantees the best routing possible, ensuring fast and
            reliable connectivity around the globe. Combine that with our robust
            SLA and a dedicated support team, and you have the WebNX Advantage - 
            a server experience truly tailored to you."
          image={CustomServer}
          hideImageOnMobile={true}
        />
      </FadeInOnScroll>

      {/* Server Standards */}
      <FadeInOnScroll>
        <CustomServerStandardsComponent />
      </FadeInOnScroll>

      {/* Dedicated Server Locations */}
      <FadeInOnScroll>
        <DedicatedServerLocations />
      </FadeInOnScroll>

      {/* Test Network Speeds */}
      <FadeInOnScroll>
        <TestNetworkSpeedsComponent />
      </FadeInOnScroll>
    </div>
  );
};

export default CustomDedicatedServersPage;
