// Import Libraries
import React from "react";
import { Helmet } from "react-helmet";

// Import SCSS
import styles from "./GpuEnabledServersPage.module.scss";

// Import Components
import HeroComponent from "../../Layout/Components/HeroComponent/HeroComponent";
import ServerPageIntro from "../../Components/ServerPageIntro/ServerPageIntro";
import FilterComponent from "../../Components/FilterComponent/FilterComponent";
import CustomServerStandardsComponent from "../DedicatedServersPage/Components/CustomServerStandardsComponent/CustomServerStandardsComponent";
import DedicatedServerLocations from "../DedicatedServersPage/Components/DedicatedServerLocations/DedicatedServerLocations";
import TestNetworkSpeedsComponent from "../DedicatedServersPage/Components/TestNetworkSpeedsComponent/TestNetworkSpeedsComponent";

// Import Assets
import GPU_Enabled_Server from "../../Assets/AI_Capable_Server.png";

// Import Animations
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";

const GpuEnabledServersPage = () => {
  return (
    <div>
      {/* Helmet for Metadata */}
      <Helmet>
        <title>GPU Enabled Servers | High-Performance GPU Hosting by WebNX</title>
        <meta
          name="description"
          content="Discover WebNX GPU-enabled dedicated servers for AI, deep learning, and scientific computing. High-performance GPU servers for unparalleled acceleration."
        />
        <meta
          name="keywords"
          content="GPU enabled servers, GPU dedicated servers, AI servers, deep learning servers, scientific computing, WebNX GPU hosting"
        />
        <meta property="og:title" content="GPU Enabled Servers | High-Performance GPU Hosting" />
        <meta
          property="og:description"
          content="Unleash the power of GPU-optimized servers for AI, deep learning, and scientific applications with WebNX GPU hosting solutions."
        />
        <meta property="og:image" content="https://webnx.com/assets/gpu-server.jpg" />
        <meta property="og:url" content="https://webnx.com/gpu-enabled-servers" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webnx.com/gpu-enabled-servers" />
      </Helmet>

      {/* Hero Section */}
      <HeroComponent
        title="GPU Enabled Dedicated Servers"
        subtitle="Unleash Power with <br /> GPU-Optimized Servers for <br /> High-Performance Computing"
        buttonText1="Shop Servers Now"
        buttonLink1="#filterComponent"
        showButton1={true}
        buttonText2="More Information"
        buttonLink2="#moreInfo"
        showButton2={true}
      />

      {/* Filter Component */}
      <FilterComponent defaultFilters={{ ServerType: ["GPU Enabled"] }} />

      {/* GPU Server Page Intro */}
      <FadeInOnScroll>
        <ServerPageIntro
          header="WebNX <br />GPU Enabled Dedicated Servers"
          subHeader="Harness the Power of GPU-Accelerated <br />Computing with WebNX"
          text="Are you searching for high-performance GPU-enabled servers to supercharge your applications? Look no further. WebNX proudly offers GPU-enabled dedicated servers, now available from our Ogden, Utah Data Center. If your workload demands the unparalleled power of GPU acceleration, our servers are ready to deliver. <br /> <br /> Equipped with the Intel Xeon E3-1275v6 and the EVGA GTX 1080TI, our standard GPU-enabled configurations provide up to 64GB of RAM and over 3,500 CUDA cores, meeting the needs of even the most resource-intensive applications. These servers are ideal for AI, deep learning, scientific computing, and rendering tasks. If the GTX 1080TI isn’t exactly what you need, our team can custom-build configurations tailored specifically to your project.<br /> <br /> Reach out to our Sales team to explore all available options. Machine Learning Servers Maximize your machine learning and data mining capabilities with the powerful parallel processing of our GPU-enabled servers. With massive CUDA core power, these machines are specifically configured to enhance the performance of complex algorithms and high-volume computations. Let us help you build the perfect machine learning server. Contact our Sales team at Sales@webnx.com for more information or to place an order today! Order Your GPU-Enabled Server Today! Experience the WebNX advantage—order your GTX 1080TI GPU-enabled server and push your applications to new heights!"
          image={GPU_Enabled_Server}
          hideImageOnMobile={true}
        />
      </FadeInOnScroll>

      {/* Server Standards */}
      <FadeInOnScroll>
        <CustomServerStandardsComponent />
      </FadeInOnScroll>

      {/* Dedicated Server Locations */}
      <FadeInOnScroll>
        <DedicatedServerLocations />
      </FadeInOnScroll>

      {/* Test Network Speeds */}
      <FadeInOnScroll>
        <TestNetworkSpeedsComponent />
      </FadeInOnScroll>
    </div>
  );
};

export default GpuEnabledServersPage;
