// Import Libraries
import React from "react";

// Import SCSS
import styles from "./WhyChooseUsComponent.module.scss";

//Import Assets
import BurstablePortIcon from "../../../../Assets/icons/BurstablePort.svg";
import PowerIcon from "../../../../Assets/icons/PowerIcon.svg";
import BatteryIcon from "../../../../Assets/icons/BatteryIcon.svg";
import GraphsIcon from "../../../../Assets/icons/GraphsIcon.svg";

const WhyChooseUsComponent = () => {
  return (
    <div>
      <div className={styles.background}>
        <div className={styles.container}>
          <div className={styles.col1}>
            <h3 className={styles.header}>
              Included In All <br /> Locations
            </h3>
            <h4 className={styles.subHeader}>Included Features</h4>
            <p className={styles.text}>
              All WebNX colocation plans come with key features designed to
              provide reliable performance, seamless server management, and the
              flexibility your business requires.
            </p>
          </div>
          <div className={styles.col2}>
            <div className={styles.iconContainer}>
              <div className={styles.iconItem}>
                <img
                  src={BurstablePortIcon}
                  alt="100mbps Fully Burstable Port"
                />
                <p>100mbps Fully Burstable Port</p>
              </div>
              <div className={styles.iconItem}>
                <img src={PowerIcon} alt="Remote Rebooter" />
                <p>Remote Rebooter</p>
              </div>
              <div className={styles.iconItem}>
                <img src={BatteryIcon} alt="1 Amp 120v Power" />
                <p>1 Amp 120v Power</p>
              </div>
              <div className={styles.iconItem}>
                <img src={GraphsIcon} alt="Bandwidth Graphs" />
                <p>Bandwidth Graphs</p>
              </div>
              <div className={styles.iconItem}>
                <h6>5</h6>
                <p>5 Useable IP Addresses</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.blueStrip}></div>
    </div>
  );
};

export default WhyChooseUsComponent;
