import React from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import styles from "./ServerPageIntro.module.scss";

const ServerPageIntro = ({
  header,
  subHeader,
  text,
  image,
  showButton,
  buttonText,
  buttonLink,
  reverse,
  hideImageOnMobile,
}) => {
  // Sanitize the incoming HTML
  const cleanHeader = DOMPurify.sanitize(header);
  const cleanSubHeader = DOMPurify.sanitize(subHeader);
  const cleanText = DOMPurify.sanitize(text);

  return (
    <section className={styles.container} id="moreInfo">
      <div
        className={`${styles.dedicatedServersContainer} ${
          reverse ? styles.reverse : ""
        }`}
      >
        {/* Textual Content */}
        <div className={styles.dedicatedServersLeft}>
          <h2
            className={styles.dedicatedServersHeader}
            dangerouslySetInnerHTML={{ __html: cleanHeader }}
          />
          <h3
            className={styles.dedicatedServersSubHeader}
            dangerouslySetInnerHTML={{ __html: cleanSubHeader }}
          />
          <p
            className={styles.dedicatedServersText}
            dangerouslySetInnerHTML={{ __html: cleanText }}
          />
          {showButton && (
            <a
              href={buttonLink}
              rel="noopener noreferrer"
              className={styles.button}
              aria-label={buttonText}
            >
              {buttonText}
            </a>
          )}
        </div>

        {/* Image Content */}
        <div
          className={`${styles.imageContainer} ${
            hideImageOnMobile ? styles.hideOnMobile : ""
          }`}
        >
          <img
            className={styles.standardServer}
            src={image}
            alt={header.replace(/<br\s*\/?>/gi, " ")} // Create a meaningful alt attribute from the header
          />
        </div>
      </div>
    </section>
  );
};

ServerPageIntro.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  reverse: PropTypes.bool,
};

ServerPageIntro.defaultProps = {
  header: "WebNX Dedicated Servers",
  subHeader: "Dedicated Servers",
  text: `Hardware changes fast in the dedicated server hosting market...`,
  showButton: false,
  buttonText: "Learn More",
  buttonLink: "#",
  reverse: false,
  hideImageOnMobile: false, // Default value
};

export default ServerPageIntro;
