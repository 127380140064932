// Import Libraries
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion'
// Import SCSS
import styles from './ServerUpgradesComponent.module.scss';

// Data structure
const data = [
  {
    title: "Memory",
    note: "",
    items: [{ name: "+1 GB DDR 3/4 Ram", cost: "$1" }]
  },
  {
    title: "Spinning Disks",
    note: "Prices are for additional drives. Contact sales for drive upgrade pricing.",
    items: [
      { name: "1TB HDD", cost: "$5" },
      { name: "2TB HDD", cost: "$7" },
      { name: "3TB HDD", cost: "$10" },
      { name: "4TB HDD", cost: "$15" },
      { name: "6TB HDD", cost: "$20" },
      { name: "8TB HDD", cost: "$25" },
      { name: "10TB HDD", cost: "$35" },
      { name: "12TB HDD", cost: "$45" },
      { name: "16TB HDD", cost: "$55" },
      { name: "18TB HDD", cost: "$70" }
    ]
  },
  {
    title: "NVMe",
    note: "Prices are for additional drives. Contact sales for drive upgrade pricing.",
    items: [
      { name: "128GB NVMe", cost: "$5" },
      { name: "256GB NVMe", cost: "$10" },
      { name: "1.2TB NVMe", cost: "$15" },
      { name: "3.2TB NVMe", cost: "$50" },
      { name: "15TB NVMe", cost: "$170" }
    ]
  },
  {
    title: "SATA SSD",
    note: "Prices are for additional drives. Contact sales for drive upgrade pricing.",
    items: [
      { name: "128GB SSD", cost: "$7" },
      { name: "256GB SSD", cost: "$10" },
      { name: "512GB SSD", cost: "$12" },
      { name: "1TB SSD", cost: "$15" },
      { name: "2TB SSD", cost: "$25" }
    ]
  },
  {
    title: "Raid Upgrades",
    note: "",
    items: [
      { name: "Raid 0/1 (No BBU)", cost: "$20" },
      { name: "Raid 0/1/5/6/10, 1GB cache & BBU", cost: "$40" }
    ]
  },
  {
    title: "Remote Management Options",
    note: "",
    items: [{ name: "Spider KVM", cost: "$30" }]
  },
  {
    title: "GPU",
    note: "We can special order any video card as needed. Contact sales@webnx.com",
    items: []
  },
  {
    title: "Operating Systems",
    note: "",
    items: [
      { name: "Windows 2008 Standard", cost: "$5 per every 2 Cores" },
      { name: "Windows 2012 Standard", cost: "$5 per every 2 Cores" },
      { name: "Windows 2016 Standard", cost: "$5 per every 2 Cores" },
      { name: "Windows 2008 Enterprise", cost: "$5 per every 2 Cores" },
      { name: "Windows 2008 Data Center", cost: "$29 per every 2 Cores" },
      { name: "Windows 2012 Data Center", cost: "$29 per every 2 Cores" },
      { name: "Windows 2016 Data Center", cost: "$29 per every 2 Cores" },
      { name: "CentOS", cost: "No Charge" },
      { name: "FreeBSD", cost: "No Charge" },
      { name: "Other Linux/BSD", cost: "No Charge" }
    ]
  },
  {
    title: "Control Panels",
    note: "",
    items: [
      { name: "Cpanel Metal WHM", cost: "$45" },
      { name: "Cpanel / Cloud WHM", cost: "$17.50" },
      { name: "Direct Admin (free with servers over $150 monthly)", cost: "$10" }
    ]
  },
  {
    title: "IP Options",
    note: "Prices are for additional IP ranges. Contact sales for more options.",
    items: [
      { name: "/29 IPv4 (5 usable)", cost: "$10" },
      { name: "/28 IPv4 (13 usable)", cost: "$26" },
      { name: "/64 IPv6", cost: "$0" }
    ]
  }
];

// ExpandableTable component
const ExpandableTable = ({ title, note, items }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <motion.div
        className={`${styles.tableContainer} ${isOpen ? styles.open : ''}`}
      >
        <motion.div
          className={styles.titleRow}
          onClick={() => setIsOpen(!isOpen)}
          whileHover={{ scale: 1.01, backgroundColor: "#f0f4f8" }}
          whileTap={{ scale: 0.98 }}
          transition={{ duration: 0.2 }}
        >
          <h3 className={styles.title}>{title}</h3>
          <motion.div
            animate={{ rotate: isOpen ? 90 : 0 }}
            transition={{ duration: 0.2 }}
            className={styles.indicator}
            whileHover={{ scale: 1.1 }}
          >
            ➤
          </motion.div>
        </motion.div>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.div
              key="content"
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              style={{ overflow: "hidden" }}
            >
              {note && <p className={styles.note}>{note}</p>}
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th className={styles.headerCell}>Item</th>
                    <th className={styles.headerCell}>Monthly Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={index}>
                      <td className={styles.cell}>{item.name}</td>
                      <td className={styles.cell}>{item.cost}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  };
  
  const ServerUpgradesComponent = () => {
    return (
      <div className={styles.background}>
        <div className={styles.container}>
          <h1 className={styles.header}>Server Upgrades</h1>
          <h2 className={styles.subHeader}>Dedicated Server Upgrades</h2>
          <p className={styles.text}>
            Upgrading your WebNX dedicated server is easy. We keep a large inventory of AMD & Intel processors, DDR3 & DDR4 ECC Ram, sata, sas, scsi and SSD hardrives and Raid cards, on hand to allow us to service our customers hardware needs. Don’t see the dedicated server upgrades you need in the list below? Contact Us and we will do our best to fulfill your request.
          </p>
          {data.map((section, index) => (
            <ExpandableTable key={index} title={section.title} note={section.note} items={section.items} />
          ))}
        </div>
      </div>
    );
  };
  
  export default ServerUpgradesComponent;