import React, { useState } from "react";

// Import SCSS
import styles from "./NetworkInfoComponent.module.scss";
import ImageComponent from "../ImageComponent/ImageComponent";

// Import Assets
import Datacenter1 from "../../../../Assets/datacenter1.png";
import Datacenter2 from "../../../../Assets/datacenter2.png";
import Datacenter3 from "../../../../Assets/datacenter3.png";
import Datacenter4 from "../../../../Assets/datacenter4.png";
import Datacenter5 from "../../../../Assets/datacenter5.png";

// Data for each location
const locationsData = {
  utah: {
    title: "Ogden, Utah Data Center and Network",
    description:
      "Explore WebNX's Ogden, Utah Data Center offering over 100,000 sqft of colocation space. Enjoy high-performance GBP Route Optimized networks and connections to global carriers.",
    datacenterInfo: `From single server colocation to multiple racks and private suites, WebNX has over 100,000 sqft of colocation space. 
    We accommodate even the largest clients. With multiple connectivity options ranging from cross-connects to any provider in Salt Lake City 
    to our own High-Performance GBP Route Optimized network, WebNX Ogden, Utah Colocation is ideal for clients who need optimized connectivity 
    to all parts of the world. To order or request a custom quote, contact us.`,
    networkInfo: {
      overview: `Our network is designed for optimal routing and performance. With real-time BGP route optimization via Noction IRP 
      and direct uplinks to major carriers, we provide best-in-class connectivity for our clients.`,
      features: [
        "Direct Uplinks: Connections to major carriers like Level3, NTT, Zayo, Telia, and China Unicom.",
        "Asia Optimization: Real-time route optimization with direct links to China Unicom and peering with numerous APAC ISPs.",
        "Capacity: Over 500Gbps total capacity with multiple 10Gbps and 100Gbps links for seamless performance.",
      ],
    },
    images: [Datacenter1, Datacenter2, Datacenter3, Datacenter4, Datacenter5],
  },
  california: {
    title: "Los Angeles, California Data Center and Network",
    description:
      "Discover WebNX's Los Angeles Data Center for premium colocation services. Benefit from BGP Route Optimized networks and exceptional connectivity to major carriers.",
    datacenterInfo: `From single server colocation to multiple racks and private suites, WebNX offers premium colocation services 
    in Los Angeles, California. With cutting-edge infrastructure and high-performance network options, this location is ideal for businesses 
    requiring superior connectivity and global reach. To order or request a custom quote, contact us.`,
    networkInfo: {
      overview: `Our Los Angeles network is engineered for maximum performance, featuring direct uplinks to key carriers and 
      real-time BGP optimization to ensure optimal data transfer.`,
      features: [
        "Direct Uplinks: Access to major carriers including NTT, Zayo, Telia, and Level3.",
        "Global Reach: Connectivity optimized for APAC and global traffic.",
        "Scalability: Extensive bandwidth capacity with multi-gigabit links.",
      ],
    },
    images: [Datacenter2, Datacenter3, Datacenter4, Datacenter5, Datacenter1],
  },
  newyork: {
    title: "Manhattan, New York Data Center and Network",
    description:
      "WebNX's Manhattan, New York Data Center provides top-tier colocation services with optimized connectivity to APAC and beyond.",
    datacenterInfo: `The Manhattan, New York Data Center by WebNX provides unparalleled colocation services in the heart of New York City. 
    Equipped with high-speed networks and secure facilities, it ensures reliability for your business needs. To order or request a custom quote, contact us.`,
    networkInfo: {
      overview: `WebNX's New York network is designed to offer seamless connectivity with global carriers, backed by real-time optimization 
      for top-tier performance.`,
      features: [
        "Direct Uplinks: Connections to major ISPs for seamless communication.",
        "Strategic Location: Optimized for East Coast and global traffic routing.",
        "Redundancy: Multiple carrier options ensure uninterrupted service.",
      ],
    },
    images: [Datacenter3, Datacenter4, Datacenter5, Datacenter1, Datacenter2],
  },
};

const NetworkInfoComponent = () => {
  const [selectedLocation, setSelectedLocation] = useState("utah");

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  const { title, description, datacenterInfo, networkInfo, images } =
    locationsData[selectedLocation];

  return (
    <div>
      <div className={styles.background}>
        <div className={styles.selectBarContainer}>
          <nav
            className={styles.selectBar}
            aria-label="Data center location selector"
          >
            {Object.keys(locationsData).map((location) => {
              const locationLabels = {
                utah: "Utah",
                california: "California",
                newyork: "New York",
              };

              return (
                <p
                  key={location}
                  onClick={() => handleLocationChange(location)}
                  className={selectedLocation === location ? styles.active : ""}
                >
                  {locationLabels[location]}
                </p>
              );
            })}
          </nav>
        </div>
      </div>

      <section className={styles.background}>
        <div className={styles.container}>
          <h1 className={styles.title}>{title}</h1>
          <meta name="description" content={description} />
          <div className={styles.colContainer}>
            <article className={styles.col1}>
              <h2 className={styles.subTitle}>Datacenter Information</h2>
              <p className={styles.text}>{datacenterInfo}</p>
            </article>
            <aside className={styles.col2}>
              <h2 className={styles.subTitle}>Network Overview</h2>
              <p className={styles.text}>{networkInfo.overview}</p>
              <h3 className={styles.listTitle}>Key Network Features</h3>
              <ul className={styles.featureList}>
                {networkInfo.features.map((feature, index) => (
                  <li key={index} className={styles.featuredText}>
                    {feature}
                  </li>
                ))}
              </ul>
            </aside>
          </div>
        </div>
      </section>

      <ImageComponent images={images} />
    </div>
  );
};

export default NetworkInfoComponent;
