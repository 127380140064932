import axiosInstance from "../util/AxiosInstance/AxiosInstance";

export const sendColocationQuoteForm = async (formData, captchaToken) => {
    try {
      // Combine form data with the captcha token for submission
      const dataToSend = { ...formData, captchaToken };
  
      // Send the form data to the API endpoint
      const response = await axiosInstance.post("/contact/colocation-quote", dataToSend);
  
      // Handle success response
      if (response.status === 200) {
        return { success: true, message: response.data.message || "Quote request submitted successfully!" };
      }
  
      // Handle failure response
      console.error("Colocation quote submission failed:", response.data);
      return { success: false, message: response.data.error || "Quote submission failed." };
    } catch (error) {
      // Handle error during the submission process
      console.error("Error Sending Colocation Quote Form:", error.response?.data || error.message);
      return { success: false, message: "An error occurred. Please try again." };
    }
  };
  