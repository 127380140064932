// Import Libraries
import React from "react";

// Import SCSS
import styles from "./ContactComponent.module.scss";

// Import Assets
import PaperAirplane from "../../../../Assets/icons/PaperAirplane.svg";
import Phone from "../../../../Assets/icons/Call.svg";
import Email from "../../../../Assets/icons/Email.svg";
import ContactFormComponent from "../ContactFormComponent/ContactFormComponent";

const ContactComponent = () => {
  return (
    <section className={styles.background} aria-labelledby="contact-us">
      {/* SEO Metadata */}
      <meta
        name="description"
        content="Contact WebNX for dedicated server support, sales inquiries, or technical assistance. Reach us via email or phone for tailored hosting solutions."
      />
      <div className={styles.container}>
        <ContactFormComponent />

        <div className={styles.col2}>
          <div className={styles.group1}>
            <h2 id="contact-us" className={styles.header}>
              Dedicated Server Customer Support
            </h2>
            <p className={styles.text}>
              Our dedicated support team is here to help with any server needs,
              ensuring smooth, reliable performance for your setup.{" "}
              <span>
                Phone calls and Live Chat are unacceptable methods for
                submitting Abuse Notices under any circumstance. Please use one
                of the above options.
              </span>
            </p>
            <div className={styles.submitSupportTicketContainer}>
              <img
                className={styles.plane}
                src={PaperAirplane}
                alt="Submit support ticket icon"
              />
              <a
                href="https://clients.webnx.com/submitticket.php?step=2&deptid=6"
                className={styles.submitSupportTicket}
                title="Submit a Support Ticket"
              >
                SUBMIT SUPPORT TICKET
              </a>
            </div>
          </div>

          <div className={styles.group2}>
            <h2 className={styles.header}>Get In Touch</h2>
            <div className={styles.contactGrid}>
              {[
                {
                  department: "Sales Department",
                  email: "sales@WebNX.com",
                  phone: "1-800-840-5996 ext. 1",
                },
                {
                  department: "Billing Department",
                  email: "accounts@WebNX.com",
                  phone: "1-800-840-5996 ext. 2",
                },
                {
                  department: "Technical Support",
                  email: "support@WebNX.com",
                  phone: "1-800-840-5996 ext. 3",
                },
                {
                  department: "Abuse Department",
                  email: "abuse@WebNX.com",
                  address: (
                    <>
                      Postal: WebNX Inc. <br />
                      C/O Abuse Department <br />
                      119 N 600 W <br />
                      Ogden, UT, 84404
                    </>
                  ),
                },
              ].map((contact, index) => (
                <div className={styles.contactGroup} key={index}>
                  <h3 className={styles.subHeader}>{contact.department}</h3>
                  {contact.email && (
                    <div className={styles.emailGroup}>
                      <img
                        className={styles.contactIcon}
                        src={Email}
                        alt="Email Icon"
                      />
                      <p>{contact.email}</p>
                    </div>
                  )}
                  {contact.phone && (
                    <div className={styles.phoneGroup}>
                      <img
                        className={styles.contactIcon}
                        src={Phone}
                        alt="Phone Icon"
                      />
                      <p>{contact.phone}</p>
                    </div>
                  )}
                  {contact.address && (
                    <div className={styles.phoneGroup}>
                      <p className={styles.address}>{contact.address}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactComponent;
