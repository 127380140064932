import React from "react";
import { motion } from "framer-motion";
import styles from "./CustomServerStandardsComponent.module.scss";

// Import Assets
import BellIcon from "../../../../Assets/icons/Bell-Blue.svg";
import Wifi from "../../../../Assets/icons/Wifi-Blue.svg";
import PowerIcon from "../../../../Assets/icons/Power-Blue.svg";
import BandwidthIcon from "../../../../Assets/icons/Bandwidth-Blue.svg";
import ShieldIcon from "../../../../Assets/icons/Shield-Blue.svg";
import NetworkIcon from "../../../../Assets/icons/Network-Blue.svg";

// Define the hover animation
const cardHover = {
  hover: {
    scale: 1.02,
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
    transition: { duration: 0.3 },
  },
};

const CustomServerStandardsComponent = () => {
  return (
    <div className={styles.container} id="moreInformation">
      <h2>Server Standards</h2>
      <h3>Included With All Dedicated Servers</h3>
      <p>
        WebNX servers are designed for top performance and reliability.
        With 24x7x365 on-site support, high-speed network ports, flexible
        bandwidth options, advanced monitoring, and premium hardware from Intel
        and AMD, we provide the security and control your business needs.
      </p>

      <div className={styles.groupContainerBig}>
        {/* Each feature wrapped in a motion.div for animation */}
        <motion.div
          className={`${styles.leftGroup} ${styles.item1}`}
          variants={cardHover}
          whileHover="hover"
        >
          <img
            className={styles.icon}
            src={Wifi}
            alt="24x7 On-Site and Network Support"
          />
          <div>
            <h4 className={styles.groupHeader}>24x7x365 On-Site & Network Support</h4>
            <p className={styles.groupText}>
              All WebNX Data Centers are staffed 24 hours a day, 7 days a week, 365 days a year. We offer support for network and hardware issues by phone and our client portal.
            </p>
          </div>
        </motion.div>

        <motion.div
          className={`${styles.leftGroup} ${styles.item3}`}
          variants={cardHover}
          whileHover="hover"
        >
          <img
            className={styles.icon}
            src={BellIcon}
            alt="High-Quality Hardware"
          />
          <div>
            <h4 className={styles.groupHeader}>High-Quality Hardware</h4>
            <p className={styles.groupText}>
              Our servers feature components from industry leaders such as Intel, AMD, SuperMicro, and SLI, ensuring maximum performance and reliability.
            </p>
          </div>
        </motion.div>

        <motion.div
          className={`${styles.leftGroup} ${styles.item4}`}
          variants={cardHover}
          whileHover="hover"
        >
          <img
            className={styles.icon}
            src={BandwidthIcon}
            alt="Flexible Bandwidth Options"
          />
          <div>
            <h4 className={styles.groupHeader}>Flexible Bandwidth</h4>
            <p className={styles.groupText}>
              Select from 20TB on a 1Gbps port or a 100Mbps dedicated unmetered option. Need more? We offer extensive upgrade options to meet your needs.
            </p>
          </div>
        </motion.div>

        <motion.div
          className={`${styles.leftGroup} ${styles.item5}`}
          variants={cardHover}
          whileHover="hover"
        >
          <img
            className={styles.icon}
            src={ShieldIcon}
            alt="Advanced Monitoring and Control"
          />
          <div>
            <h4 className={styles.groupHeader}>Advanced Monitoring and Control</h4>
            <p className={styles.groupText}>
              Monitor your bandwidth usage and perform remote reboots directly from the WebNX Client Portal. Most servers also include IPMI access for BIOS-level control.
            </p>
          </div>
        </motion.div>

        <motion.div
          className={`${styles.leftGroup} ${styles.item6}`}
          variants={cardHover}
          whileHover="hover"
        >
          <img
            className={styles.icon}
            src={NetworkIcon}
            alt="High-Speed Public and Private Network"
          />
          <div>
            <h4 className={styles.groupHeader}>1Gbps Public and Private Network</h4>
            <p className={styles.groupText}>
              High-speed connections with private and public ports. Our private network links your servers, allowing for unmetered transfers between them without using your public bandwidth – perfect for clustering or backups.
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default CustomServerStandardsComponent;
