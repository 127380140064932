// Import Libraries
import React, { useState } from "react";
import Lottie from "react-lottie";

// Import SCSS
import styles from "./CustomServerQuoteForm.module.scss";

// Import Animations
import successAnimationData from ".././../../../Assets/video/Confetti.json";
import FadeInOnScroll from "../../../../util/Animations/FadeInOnScroll";

// Import Components
import CaptchaModalComponent from "../../../../Components/CaptchaModalComponent/CaptchaModalComponent";

// Import ApiLayer
import { sendServerOrderForm } from "../../../../ApiLayer/sendServerOrderForm";

const CustomServerQuoteForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    cpuBrand: "",
    cpuBrandCustom: "", // Added custom field for CPU Brand
    cpuModel: "",
    cpuModelCustom: "", // Added custom field for CPU Model
    cpuSpecificModel: "",
    cpuSpecificModelCustom: "", // Added custom field for Specific CPU Model
    cpuCores: "",
    ramSize: "",
    storageType: "",
    storageCapacity: "",
    raidConfiguration: "",
    gpuEnabled: "No",
    gpuCount: "",
    gpuModel: "",
    gpuModelCustom: "", // Add custom field for GPU model
    gpuMemory: "",
    networkSpeed: "",
    ipCount: "",
    osType: "",
    additionalNotes: "",
  });

  const [errors, setErrors] = useState({});
  const [statusMessage, setStatusMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  };

  const cpuOptions = {
    AMD: ["Ryzen", "EPYC", "Threadripper"],
    Intel: ["Core", "Xeon"],
  };

  const modelOptions = {
    Ryzen: {
      specificModels: [
        { label: "Ryzen 9 9950X", cores: "16", threads: "32" },
        { label: "Ryzen 7 9800X3D", cores: "8", threads: "16" },
        { label: "Ryzen 5 9600X", cores: "6", threads: "12" },
        { label: "Ryzen 5 7600", cores: "6", threads: "12" },
        { label: "Ryzen 7 7700", cores: "8", threads: "16" },
        { label: "Ryzen 7 7700X", cores: "8", threads: "16" },
        { label: "Ryzen 7 8700G", cores: "8", threads: "16" },
        { label: "Ryzen 9 7900", cores: "12", threads: "24" },
        { label: "Ryzen 9 7950X3D", cores: "16", threads: "32" },
        { label: "Ryzen 9 9900X", cores: "12", threads: "16" },
        { label: "Ryzen 9 9950X", cores: "16", threads: "32" },
      ],
    },
    EPYC: {
      specificModels: [
        { label: "EPYC 7443P", cores: "24", threads: "48" },
        { label: "EPYC 7402P", cores: "24", threads: "48" },
        { label: "EPYC 7543", cores: "32", threads: "64" },
        { label: "EPYC 9354P", cores: "32", threads: "64" },
        { label: "EPYC 9354", cores: "32", threads: "64" },
        { label: "EPYC 7742", cores: "64", threads: "128" },
        { label: "EPYC 9554", cores: "64", threads: "128" },
        { label: "EPYC 9654", cores: "96", threads: "192" },
        { label: "Dual EPYC 7742", cores: "128", threads: "256" },
        { label: "Dual EPYC 9754", cores: "256", threads: "512" },
      ],
    },
    Threadripper: {
      specificModels: [
        { label: "Threadripper 7970X", cores: "32", threads: "64" },
        { label: "Threadripper 7960X", cores: "24", threads: "48" },
        { label: "Threadripper 7980X", cores: "64", threads: "128" },
      ],
    },
    Core: {
      specificModels: [
        { label: "Core i9-14900K", cores: "24", threads: "32" },
        { label: "Core i7-14700K", cores: "20", threads: "28" },
        { label: "Core i5-14600K", cores: "14", threads: "20" },
        { label: "Intel i9-14900K", cores: "24", threads: "32" },
      ],
    },
    Xeon: {
      specificModels: [
        { label: "Xeon Platinum 8490H", cores: "60", threads: "120" },
        { label: "Xeon Gold 6348", cores: "28", threads: "56" },
        { label: "Xeon Silver 4314", cores: "16", threads: "32" },
        { label: "Xeon W-1250", cores: "6", threads: "12" },
        { label: "Dual Xeon E5-2690v4", cores: "28", threads: "56" },
      ],
    },
  };

  const gpuModels = [
    "NVIDIA A100",
    "NVIDIA Tesla V100",
    "RTX 3090",
    "RTX 4060",
    "RTX 4060 Ti",
    "RTX 4090",
    "A40 GPU",
    "2 x A100 GPUs",
    "4 x A100 80GB GPU",
  ];

  const storageOptions = [
    "SSD",
    "NVMe",
    "HDD",
    "Gen4 NVMe",
    "PCIE4 NVMe",
    "PCIE5 NVMe",
  ];
  const storageCapacities = [
    "500GB",
    "960GB",
    "1TB",
    "2TB",
    "3.8TB",
    "3.84TB",
    "4TB",
    "6TB",
    "6.4TB",
    "12TB",
    "15.4TB",
    ">15.4TB",
  ];
  const raidOptions = ["No RAID", "RAID 0", "RAID 1", "RAID 5", "RAID 10"];
  const networkSpeeds = [
    "1 Gbps",
    "10 Gbps",
    "10Gbps Port / 50TB Outbound",
    "10Gbps Port / 100TB Outbound",
  ];
  const ipOptions = ["1", "2", "5", "10", "20", "50", "100", "/29 IPv4"];
  const osOptions = [
    "Linux",
    "Windows Server",
    "CentOS",
    "AlmaLinux",
    "Rocky Linux",
    "OpenVZ",
    "Debian",
    "Ubuntu",
    "Disk Wipe",
    "Proxmox VE",
    "Fedora",
    "Cloud Linux",
    "Oracle Limux",
    "No OS",
  ];
  const gpuEnabledOptions = ["No", "Yes"];
  const gpuCounts = ["1", "2", "4", "8"];
  const gpuMemories = ["8GB", "16GB", "32GB", "64GB"];

  // Input change handler
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      let updatedData = { ...prevData, [name]: value };

      // Logic for GPU fields
      if (name === "gpuEnabled" && value === "No") {
        updatedData = {
          ...updatedData,
          gpuCount: "",
          gpuModel: "",
          gpuMemory: "",
        };
      }

      // Logic for cascading "Other" selection in CPU fields
      if (name === "cpuBrand") {
        if (value === "Other") {
          updatedData = {
            ...updatedData,
            cpuModel: "Other",
            cpuSpecificModel: "Other",
          };
        } else {
          updatedData = {
            ...updatedData,
            cpuBrandCustom: "",
            cpuModel: "",
            cpuModelCustom: "",
            cpuSpecificModel: "",
            cpuSpecificModelCustom: "",
          };
        }
      }

      if (name === "cpuModel") {
        if (value === "Other") {
          updatedData = {
            ...updatedData,
            cpuSpecificModel: "Other",
          };
        } else {
          updatedData = {
            ...updatedData,
            cpuModelCustom: "",
            cpuSpecificModel: "",
            cpuSpecificModelCustom: "",
          };
        }
      }

      if (name === "cpuSpecificModel" && value !== "Other") {
        updatedData = {
          ...updatedData,
          cpuSpecificModelCustom: "",
        };
      }

      return updatedData;
    });
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
      isValid = false;
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleCaptchaVerification = async (token) => {
    setShowCaptchaModal(false);

    try {
      const response = await sendServerOrderForm(formData, token); // Use the payload here
      if (response.success) {
        setStatusMessage(response.message); // Show success message
        setIsError(false);
        setShowSuccessAnimation(true); // Trigger success animation
      } else {
        setIsError(true);
        setStatusMessage(response.message); // Show error message
      }
    } catch (error) {
      setIsError(true);
      setStatusMessage("An error occurred. Please try again.");
    }

    setFormData({
      name: "",
      email: "",
      phone: "",
      companyName: "",
      cpuBrand: "",
      cpuBrandCustom: "", // Reset the custom fields
      cpuModel: "",
      cpuModelCustom: "", // Reset the custom fields
      cpuSpecificModel: "",
      cpuSpecificModelCustom: "", // Reset the custom fields
      cpuCores: "",
      ramSize: "",
      storageType: "",
      storageCapacity: "",
      raidConfiguration: "",
      gpuEnabled: "No",
      gpuCount: "",
      gpuModel: "",
      gpuModelCustom: "",
      gpuMemory: "",
      networkSpeed: "",
      ipCount: "",
      osType: "",
      additionalNotes: "",
    });
    setErrors({});
    setTimeout(() => setShowSuccessAnimation(false), 5000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      setIsError(true);
      setStatusMessage("Please fill out all required fields correctly.");
      return;
    }
  
    // Show the captcha modal
    setShowCaptchaModal(true);
  };
  

  return (
    <div className={styles.background}>
      <FadeInOnScroll>
        <div className={styles.container}>
          <div className={styles.formContainer}>
            <h1 className={styles.header}>Request a Custom Server Quote</h1>
            <p className={styles.text}>
              Configure your custom server and send a quote request. Our team
              will review your specifications and get back to you.
            </p>

            {statusMessage && (
              <p className={isError ? styles.errorText : styles.successText}>
                {statusMessage}
              </p>
            )}
            {showSuccessAnimation && (
              <div className={styles.lottieTopRight}>
                <Lottie
                  options={defaultOptions}
                  height={700}
                  width={700}
                  key={Date.now()} // force re-render by changing key
                />
              </div>
            )}

            <form className={styles.quoteForm} onSubmit={handleSubmit}>
              {/* Contact Information Section */}
              {/* Contact Information Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>Contact Information</h3>
                <div className={styles.grid}>
                  {[
                    {
                      label: "Name",
                      name: "name",
                      type: "text",
                      placeholder: "Your Name",
                      error: errors.name,
                    },
                    {
                      label: "Email",
                      name: "email",
                      type: "email",
                      placeholder: "Your Email",
                      error: errors.email,
                    },
                    {
                      label: "Phone",
                      name: "phone",
                      type: "tel",
                      placeholder: "Your Phone",
                    },
                  ].map((field) => (
                    <div key={field.name} className={styles.formGroup}>
                      <label htmlFor={field.name}>{field.label}</label>
                      <input
                        type={field.type}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                      />
                      {field.error && (
                        <span className={styles.errorText}>{field.error}</span>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {/* CPU Configuration Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>CPU Configuration</h3>
                <div className={styles.grid}>
                  {/* CPU Brand */}
                  <div className={styles.formGroup}>
                    <label htmlFor="cpuBrand">CPU Brand</label>
                    <select
                      name="cpuBrand"
                      value={formData.cpuBrand}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select CPU Brand</option>
                      {Object.keys(cpuOptions).map((brand) => (
                        <option key={brand} value={brand}>
                          {brand}
                        </option>
                      ))}
                      <option value="Other">Other</option>
                    </select>
                    {formData.cpuBrand === "Other" && (
                      <input
                        type="text"
                        name="cpuBrandCustom"
                        placeholder="Enter custom CPU Brand"
                        value={formData.cpuBrandCustom || ""}
                        onChange={handleChange}
                        className={styles.textInput}
                        style={{ marginTop: "1rem" }}
                      />
                    )}
                    {errors.cpuBrand && (
                      <span className={styles.errorText}>
                        {errors.cpuBrand}
                      </span>
                    )}
                  </div>

                  {/* CPU Model */}
                  <div className={styles.formGroup}>
                    <label htmlFor="cpuModel">CPU Model</label>
                    <select
                      name="cpuModel"
                      value={formData.cpuModel}
                      onChange={handleChange}
                      disabled={
                        !formData.cpuBrand || formData.cpuBrand === "Other"
                      }
                      className={styles.selectInput}
                    >
                      <option value="">Select CPU Model</option>
                      {formData.cpuBrand &&
                        formData.cpuBrand !== "Other" &&
                        cpuOptions[formData.cpuBrand].map((model) => (
                          <option key={model} value={model}>
                            {model}
                          </option>
                        ))}
                      <option value="Other">Other</option>
                    </select>
                    {formData.cpuModel === "Other" && (
                      <input
                        type="text"
                        name="cpuModelCustom"
                        placeholder="Enter custom CPU Model"
                        value={formData.cpuModelCustom || ""}
                        onChange={handleChange}
                        className={styles.textInput}
                        style={{ marginTop: "1rem" }}
                      />
                    )}
                  </div>

                  {/* Specific Model */}
                  <div className={styles.formGroup}>
                    <label htmlFor="cpuSpecificModel">Specific Model</label>
                    <select
                      name="cpuSpecificModel"
                      value={formData.cpuSpecificModel}
                      onChange={handleChange}
                      disabled={
                        !formData.cpuModel || formData.cpuModel === "Other"
                      }
                      className={styles.selectInput}
                    >
                      <option value="">Select Specific CPU Model</option>
                      {formData.cpuModel &&
                        formData.cpuModel !== "Other" &&
                        (
                          modelOptions[formData.cpuModel]?.specificModels || []
                        ).map((model) => (
                          <option key={model.label} value={model.label}>
                            {model.label} ({model.cores} cores, {model.threads}{" "}
                            threads)
                          </option>
                        ))}
                      <option value="Other">Other</option>
                    </select>
                    {formData.cpuSpecificModel === "Other" && (
                      <input
                        type="text"
                        name="cpuSpecificModelCustom"
                        placeholder="Enter custom Specific Model"
                        value={formData.cpuSpecificModelCustom || ""}
                        onChange={handleChange}
                        className={styles.textInput}
                        style={{ marginTop: "1rem" }}
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* RAM Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>RAM Configuration</h3>
                <div className={styles.formGroup}>
                  <label htmlFor="ramSize">RAM Size</label>
                  <select
                    name="ramSize"
                    value={formData.ramSize}
                    onChange={handleChange}
                    className={styles.selectInput}
                  >
                    <option value="">Select RAM Size</option>
                    {[
                      "16GB",
                      "32GB",
                      "64GB",
                      "128GB",
                      "512",
                      "1TB",
                      ">1TB",
                    ].map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Storage Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>Storage Configuration</h3>
                <div className={styles.grid}>
                  {[
                    {
                      label: "Storage Type",
                      name: "storageType",
                      options: storageOptions,
                    },
                    {
                      label: "Storage Capacity",
                      name: "storageCapacity",
                      options: storageCapacities,
                    },
                  ].map((field) => (
                    <div key={field.name} className={styles.formGroup}>
                      <label htmlFor={field.name}>{field.label}</label>
                      <select
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        className={styles.selectInput}
                      >
                        <option value="">{`Select ${field.label}`}</option>
                        {field.options.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="raidConfiguration">RAID Configuration</label>
                  <select
                    name="raidConfiguration"
                    value={formData.raidConfiguration}
                    onChange={handleChange}
                    className={styles.selectInput}
                  >
                    <option value="">Select RAID Configuration</option>
                    {raidOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* GPU Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>GPU Configuration</h3>

                {/* GPU Enabled Dropdown */}
                <div className={styles.gridFullRow}>
                  <div className={styles.formGroup}>
                    <label htmlFor="gpuEnabled">GPU Enabled?</label>
                    <select
                      name="gpuEnabled"
                      value={formData.gpuEnabled}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      {gpuEnabledOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* GPU Details (in a 3-column grid) */}
                {formData.gpuEnabled === "Yes" && (
                  <div className={styles.grid}>
                    {/* GPU Model */}
                    <div className={styles.formGroup}>
                      <label htmlFor="gpuModel">GPU Model</label>
                      <select
                        name="gpuModel"
                        value={formData.gpuModel}
                        onChange={handleChange}
                        className={styles.selectInput}
                      >
                        <option value="">Select GPU Model</option>
                        {gpuModels.map((model) => (
                          <option key={model} value={model}>
                            {model}
                          </option>
                        ))}
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    {/* Custom GPU Model (only shown when "Other" is selected) */}
                    {formData.gpuModel === "Other" && (
                      <div
                        className={`${styles.formGroup} ${styles.gridFullWidth}`}
                      >
                        <label htmlFor="gpuModelCustom">Custom GPU Model</label>
                        <input
                          type="text"
                          name="gpuModelCustom"
                          placeholder="Enter custom GPU Model"
                          value={formData.gpuModelCustom || ""}
                          onChange={handleChange}
                          className={styles.textInput}
                        />
                      </div>
                    )}

                    {/* GPU Count */}
                    <div className={styles.formGroup}>
                      <label htmlFor="gpuCount">Number of GPUs</label>
                      <select
                        name="gpuCount"
                        value={formData.gpuCount}
                        onChange={handleChange}
                        className={styles.selectInput}
                      >
                        <option value="">Select Number of GPUs</option>
                        {gpuCounts.map((count) => (
                          <option key={count} value={count}>
                            {count}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* GPU Memory */}
                    <div className={styles.formGroup}>
                      <label htmlFor="gpuMemory">GPU Memory</label>
                      <select
                        name="gpuMemory"
                        value={formData.gpuMemory}
                        onChange={handleChange}
                        className={styles.selectInput}
                      >
                        <option value="">Select GPU Memory</option>
                        {gpuMemories.map((memory) => (
                          <option key={memory} value={memory}>
                            {memory}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>

              {/* Network Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>Network Configuration</h3>
                <div className={styles.grid}>
                  {[
                    {
                      label: "Network Speed",
                      name: "networkSpeed",
                      options: networkSpeeds,
                    },
                    {
                      label: "Number of IPs",
                      name: "ipCount",
                      options: ipOptions,
                    },
                  ].map((field) => (
                    <div key={field.name} className={styles.formGroup}>
                      <label htmlFor={field.name}>{field.label}</label>
                      <select
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        className={styles.selectInput}
                      >
                        <option value="">{`Select ${field.label}`}</option>
                        {field.options.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
                </div>
              </div>

              {/* Operating System Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>Operating System</h3>
                <div className={styles.formGroup}>
                  <label htmlFor="osType">Operating System</label>
                  <select
                    name="osType"
                    value={formData.osType}
                    onChange={handleChange}
                    className={styles.selectInput}
                  >
                    <option value="">Select OS Type</option>
                    {osOptions.map((os) => (
                      <option key={os} value={os}>
                        {os}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Additional Notes Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>Additional Notes</h3>
                <div className={styles.formGroup}>
                  <label htmlFor="additionalNotes">Additional Notes</label>
                  <textarea
                    name="additionalNotes"
                    value={formData.additionalNotes}
                    onChange={handleChange}
                    placeholder="Enter any additional information or specifications here"
                    rows="4"
                    className={styles.textAreaInput}
                  />
                </div>
              </div>

              {/* Submit Button */}
              <button type="submit" className={styles.sendButton}>
                Submit Quote Request
              </button>
            </form>
          </div>
        </div>
      </FadeInOnScroll>
      <CaptchaModalComponent
        isOpen={showCaptchaModal}
        onClose={() => setShowCaptchaModal(false)}
        onVerify={handleCaptchaVerification}
      />
    </div>
  );
};

export default CustomServerQuoteForm;
