import axiosInstance from "../util/AxiosInstance/AxiosInstance";

export const sendServerOrderForm = async (formData, captchaToken) => {
  
    try {
      const dataToSend = { ...formData, captchaToken };
  
      const response = await axiosInstance.post("/contact/custom-server-order", dataToSend);
  
      if (response.status === 200) {
        return { success: true, message: response.data.message || "Order submitted successfully!" };
      }
  
      console.error("Server order submission failed:", response.data);
      return { success: false, message: response.data.error || "Order submission failed." };
    } catch (error) {
      console.error("Error Sending Server Order Form:", error.response?.data || error.message);
      return { success: false, message: "An error occurred. Please try again." };
    }
  };
  