import React from "react";
import styles from "./TestNetworkSpeedsComponent.module.scss";

// Import Assets
import LittleGreenNetworkIcon from "../../../../Assets/icons/LittleGreenNetworkIcon.svg";
import CaliforniaCircle from "../../../../Assets/icons/CaliforniaCircle.svg";
import UtahCircle from "../../../../Assets/icons/UtahCircle.svg";
import NewYorkCircle from "../../../../Assets/icons/NewYorkCircle.svg";

const TestNetworkSpeedsComponent = () => {
  return (
    <div className={styles.container}>
      <section className={styles.topSection}>
        <h1 className={styles.dedicatedServersHeader}>
          Test Network Speeds at Our Datacenters
        </h1>
        <h2 className={styles.dedicatedServersSubHeader}>
          Ensure Optimal Performance from Coast to Coast
        </h2>
        <p className={styles.dedicatedServersText}>
          Curious about the network performance at our colocation data centers?
          Our Networking Looking Glass tool lets you test real-time routing and
          connectivity from multiple data center locations across the U.S.,
          including the West Coast, Mountain West, and East Coast. This
          transparency showcases our commitment to high-performance and
          reliable network infrastructure. Access Looking Glass pages and
          download test files below, or contact us at{" "}
          <a href="mailto:sales@webnx.com">sales@webnx.com</a> for more
          information.
        </p>
      </section>
      <section className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.cardTopRow}>
            <h3>Los Angeles, <br /> California</h3>
            <img
              className={styles.networkIcon}
              src={LittleGreenNetworkIcon}
              alt="Los Angeles network icon"
            />
          </div>
          <img
            className={styles.circleAndState}
            src={CaliforniaCircle}
            alt="Map showing Los Angeles, California"
          />
          <a
            href="https://lax-lg.webnx.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.testLink}
            aria-label="Test network speed at Los Angeles, California"
          >
            Test
          </a>
        </div>
        <div className={styles.card}>
          <div className={styles.cardTopRow}>
            <h3>Ogden, <br /> Utah</h3>
            <img
              className={styles.networkIcon}
              src={LittleGreenNetworkIcon}
              alt="Ogden network icon"
            />
          </div>
          <img
            className={styles.circleAndState}
            src={UtahCircle}
            alt="Map showing Ogden, Utah"
          />
          <a
            href="https://ogd-lg.webnx.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.testLink}
            aria-label="Test network speed at Ogden, Utah"
          >
            Test
          </a>
        </div>
        <div className={styles.card}>
          <div className={styles.cardTopRow}>
            <h3>Manhattan, <br /> New York</h3>
            <img
              className={styles.networkIcon}
              src={LittleGreenNetworkIcon}
              alt="Manhattan network icon"
            />
          </div>
          <img
            className={styles.circleAndState}
            src={NewYorkCircle}
            alt="Map showing Manhattan, New York"
          />
          <a
            href="https://nyc-lg.webnx.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.testLink}
            aria-label="Test network speed at Manhattan, New York"
          >
            Test
          </a>
        </div>
      </section>
    </div>
  );
};

export default TestNetworkSpeedsComponent;
