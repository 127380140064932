import React from "react";
import { Helmet } from "react-helmet";

// Import SCSS
import styles from "./DataCenterAndLocationsPage.module.scss";

// Import Components
import HeroComponent from "../../Layout/Components/HeroComponent/HeroComponent";
import AboutUsComponent from "./Components/AboutUsComponent/AboutUsComponent";
import WhyChooseUsComponent from "./Components/WhyChooseUsComponent/WhyChooseUsComponent";
import NetworkInfoComponent from "./Components/NetworkInfoComponent/NetworkInfoComponent";
import RevolvingLogoComponent from "./Components/RevolvingLogoComponent/RevolvingLogoComponent";

// Import Animation
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";
import TestNetworkSpeedsComponent from "../DedicatedServersPage/Components/TestNetworkSpeedsComponent/TestNetworkSpeedsComponent";
import ImageComponent from "./Components/ImageComponent/ImageComponent";

const DataCenterAndLocationsPage = () => {
  return (
    <div className={styles.dataCenterAndLocationsPage}>
      {/* React Helmet for SEO */}
      <Helmet>
        <title>Data Center Locations & Networks | WebNX</title>
        <meta
          name="description"
          content="Explore WebNX's datacenter locations and networks to learn about our advanced facilities, top-tier hardware, and high-performance network infrastructure."
        />
        <meta
          name="keywords"
          content="WebNX Data Center, Datacenter Locations, Network Infrastructure, Hosting Services, High-Performance Network"
        />
        <meta name="author" content="WebNX" />
        <meta
          property="og:title"
          content="Data Center Locations & Networks - WebNX"
        />
        <meta
          property="og:description"
          content="Discover WebNX's data center locations and advanced network infrastructure designed for reliability and performance."
        />
        <meta
          property="og:url"
          content="https://webnx.com/datacenter-locations"
        />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://webnx.com/datacenter-locations" />
      </Helmet>

      {/* Hero Section */}
      <HeroComponent
        title="About Us"
        subtitle="Datacenter Locations <br /> and Networks"
        showButton1={false} // No buttons needed
        showButton2={false}
      />

      {/* Page Components with Fade-In Animation */}
      <FadeInOnScroll>
        <NetworkInfoComponent />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <ImageComponent />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <RevolvingLogoComponent />
      </FadeInOnScroll>

      {/* Uncomment if AboutUsComponent is needed */}
      {/* <FadeInOnScroll>
        <AboutUsComponent />
      </FadeInOnScroll> */}

      <FadeInOnScroll>
        <TestNetworkSpeedsComponent />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <WhyChooseUsComponent />
      </FadeInOnScroll>
    </div>
  );
};

export default DataCenterAndLocationsPage;
