import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./FeaturedSolutionsComponent.module.scss";
import ArrowRight from "../../../../Assets/icons/Blue-Arrow.svg";

const FeaturedSolutionsComponent = () => {
  const [activeDot, setActiveDot] = useState(0);

  // Data for each category of servers
  const serverData = [
    {
      type: "Standard Servers",
      cards: [
        {
          title: "AMD Ryzen 7600",
          price: "$249.00",
          solutionsList: [
            "6 Cores / 12 Threads",
            "64GB DDR5 RAM",
            "2 x 6.4TB PCIE4 NVMe",
            "10Gbps Port / 50TB Outbound",
            "/29 IPv4",
            "No GPU",
          ],
          url: "https://clients.webnx.com/cart.php?a=confproduct&i=0",
        },
        {
          title: "AMD Ryzen 7900",
          price: "$199.00",
          solutionsList: [
            "12 Cores / 24 Threads",
            "128GB DDR5 RAM",
            "2 x 960GB PCIE4 NVMe",
            "10Gbps Port / 50TB Outbound",
            "/29 IPv4",
            "No GPU",
          ],
          url: "https://clients.webnx.com/cart.php?a=confproduct&i=1",
        },
      ],
    },
    {
      type: "GPU Enabled Servers",
      cards: [
        {
          title: "AMD EPYC 7402P",
          price: "$1,999.00",
          solutionsList: [
            "24 Cores / 48 Threads",
            "512GB RAM",
            "6.4TB NVMe",
            "10Gbps Port / 50TB Outbound",
            "/29 IPv4",
            "2 x A100 GPUs",
          ],
          url: "https://clients.webnx.com/cart.php?a=confproduct&i=2",
        },
        {
          title: "AMD EPYC 7443P",
          price: "$2,999.00",
          solutionsList: [
            "24 Cores / 48 Threads",
            "512GB RAM",
            "2 x 3.84TB Gen4 NVMe",
            "10Gbps Port / 100TB Outbound",
            "/29 IPv4",
            "4 x A100 80GB GPUs",
          ],
          url: "https://clients.webnx.com/cart.php?a=confproduct&i=3",
        },
      ],
    },
    {
      type: "Storage Servers",
      cards: [
        {
          title: "Dual AMD EPYC 7742",
          price: "$759.00",
          solutionsList: [
            "128 Cores / 256 Threads",
            "512GB DDR4 ECC RAM",
            "2 x 15.4TB NVMe",
            "10Gbps Port / 50TB Outbound",
            "/29 IPv4",
            "No GPU",
          ],
          url: "https://clients.webnx.com/cart.php?a=confproduct&i=4",
        },
        {
          title: "AMD EPYC 9354",
          price: "$899.00",
          solutionsList: [
            "32 Cores / 64 Threads",
            "768GB DDR5 ECC RAM",
            "3 x 2TB PCIE4 NVMe",
            "10Gbps Port / 50TB Outbound",
            "/29 IPv4",
            "No GPU",
          ],
          url: "https://clients.webnx.com/cart.php?a=confproduct&i=5",
        },
      ],
    },
    {
      type: "Custom Servers",
      cards: [
        {
          title: "Fully Customizable Server",
          price: "Custom Pricing",
          solutionsList: [
            "Your Choice of CPU",
            "Your Choice of Memory",
            "Your Choice of Storage",
            "Custom Bandwidth",
            "Your Choice of GPU",
          ],
          url: "/custom-config", // Example placeholder URL
        },
        {
          title: "Premium Custom Server",
          price: "Custom Pricing",
          solutionsList: [
            "High-End Configurations",
            "Tailored Storage",
            "Priority Support",
            "Custom Bandwidth",
            "Custom GPUs",
          ],
          url: "/premium-custom-config", // Example placeholder URL
        },
      ],
    },
  ];

  // Set up the timer to switch the active dot every 7 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setActiveDot((prev) => (prev + 1) % serverData.length);
    }, 7000);

    return () => clearInterval(timer);
  }, [serverData.length]);

  // Animation variants for entry and exit of cards
  const cardContainerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  // Animation for the dots
  const dotVariants = {
    inactive: { scale: 1, backgroundColor: "#ccc" },
    active: { scale: 1.4, backgroundColor: "#07587F", borderColor: "#07587F" },
    hover: { scale: 1.2 },
  };

  // Hover animation for the cards
  const cardHover = {
    hover: {
      scale: 1.02,
      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
      transition: { duration: 0.3 },
    },
  };

  return (
    <section className={styles.solutionsContainer}>
      <div className={styles.topRow}>
        <div>
          <h2 className={styles.header}>Featured Solutions</h2>
          <AnimatePresence mode="wait">
            <motion.p
              key={activeDot}
              className={styles.subHeader}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={cardContainerVariants}
              transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              {serverData[activeDot].type}
            </motion.p>
          </AnimatePresence>
        </div>
        <div className={styles.dotContainer}>
          {serverData.map((_, index) => (
            <motion.div
              key={index}
              className={`${styles.dot} ${activeDot === index ? styles.active : ""}`}
              variants={dotVariants}
              initial="inactive"
              animate={activeDot === index ? "active" : "inactive"}
              whileHover="hover"
              onClick={() => setActiveDot(index)}
              role="button"
              tabIndex={0}
              aria-label={`View ${serverData[index].type}`}
              onKeyPress={(e) => e.key === "Enter" && setActiveDot(index)}
            />
          ))}
        </div>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={activeDot}
          className={`normalSpacing ${styles.solutionsCardContainer}`}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={cardContainerVariants}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          {serverData[activeDot].cards.map((card, index) => (
            <motion.article
              key={index}
              className={`${styles.solutionsCard}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ delay: index * 0.1, duration: 0.4 }}
              whileHover="hover"
              variants={cardHover}
            >
              <div className={styles.solutionsCardTopRow}>
                <h3 className={styles.solutionsCardTitle}>{card.title}</h3>
                <p className={styles.solutionsCardInstantDeploy}>Instant Deploy</p>
              </div>
              <h4 className={styles.solutionsCardPrice}>{card.price}</h4>
              <hr />
              <div className={styles.solutionsCardBottomRow}>
                <div>
                  <p className={styles.solutionsCardIncludes}>Includes:</p>
                  <ul className={styles.solutionsList}>
                    {card.solutionsList.map((solution, i) => (
                      <li key={i}>{solution}</li>
                    ))}
                  </ul>
                </div>
                <a
                  className={styles.orderNow}
                  href={card.url}
                  aria-label={`Order ${card.title}`}
                  target="_blank" // Opens link in a new tab
                  rel="noopener noreferrer" // Security improvement
                >
                  Order Now
                </a>
              </div>
            </motion.article>
          ))}
        </motion.div>
      </AnimatePresence>
    </section>
  );
};

export default FeaturedSolutionsComponent;
