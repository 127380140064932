// Import Libraries
import React from "react";
import { motion } from "framer-motion";

// Import SCSS
import styles from "./OurServicesComponent.module.scss";

// Import Assets
import StandardServer from "../../../../Assets/Standard_Server.png";
import CustomServer from "../../../../Assets/Custom_Server.png";
import AI_Capable_Server from "../../../../Assets/AI_Capable_Server.png";
import StorageServer from "../../../../Assets/Storage_Server.png";

// Framer Motion hover animation
const cardHover = {
  hover: {
    scale: 1.02,
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
    transition: { duration: 0.3 },
  },
};

const OurServicesComponent = () => {
  return (
<section className={styles.background}>
  <div className={styles.servicesHeaderContainer}>
    <h1 className={styles.header}>Dedicated Servers</h1> {/* Use h1 for SEO */}
    <p className={styles.subHeader}>Comprehensive Server Solutions</p>
    <p className={styles.text}>
      At WebNX, we provide a full suite of server solutions to meet diverse
      business needs. From high-performance <strong>Dedicated</strong> Servers
      that offer unparalleled control and security, to <strong>Custom</strong>{" "}
      Dedicated Servers crafted to handle unique workloads with tailored
      hardware and software configurations, our offerings ensure optimized
      performance. <br />
      <br />
      For intensive computing tasks, our <strong>GPU-Enabled</strong> Servers
      bring the power of advanced GPUs, ideal for applications like AI, deep
      learning, and rendering. Additionally, our{" "}
      <strong>Storage Servers</strong> deliver scalable, secure, and
      high-capacity solutions, perfect for managing extensive data, backups,
      and media storage. Whatever your requirements, WebNX has a server
      solution designed to support your business's growth and efficiency.
    </p>
  </div>
  <div className={styles.servicesContainer}>
    <div className={styles.cardContainer}>
      {/* Dedicated Servers Card */}
      <a href="/standard-dedicated-servers" aria-label="Learn more about Dedicated Servers">
        <motion.div className={styles.card} variants={cardHover} whileHover="hover">
          <div className={styles.cardTopRow}>
            <img
              className={styles.image}
              src={StandardServer}
              alt="Dedicated Servers"
              loading="lazy"
            />
            <div className={styles.cardHeaderContainer}>
              <h2 className={styles.cardHeader}>Dedicated Servers</h2>
              <p className={styles.cardSubHeader}>Instant Provision</p>
            </div>
          </div>
          <p className={styles.cardText}>
            High-performance dedicated servers offering complete control,
            security, and reliability for all your business needs.
          </p>
          <p className={styles.cardLearnMore}>Learn More</p>
        </motion.div>
      </a>

      {/* Custom Servers Card */}
      <a href="/custom-dedicated-servers" aria-label="Learn more about Custom Servers">
        <motion.div className={styles.card} variants={cardHover} whileHover="hover">
          <div className={styles.cardTopRow}>
            <img
              className={styles.image}
              src={CustomServer}
              alt="Custom Servers"
              loading="lazy"
            />
            <div className={styles.cardHeaderContainer}>
              <h2 className={styles.cardHeader}>Custom Servers</h2>
              <p className={styles.cardSubHeader}>Custom Builds</p>
            </div>
          </div>
          <p className={styles.cardText}>
            Tailor-made dedicated servers built to your exact
            specifications, ensuring optimized performance for even the most
            demanding workloads.
          </p>
          <p className={styles.cardLearnMore}>Learn More</p>
        </motion.div>
      </a>

      {/* Storage Servers Card */}
      <a href="/storage-servers" aria-label="Learn more about Storage Servers">
        <motion.div className={styles.card} variants={cardHover} whileHover="hover">
          <div className={styles.cardTopRow}>
            <img
              className={styles.image}
              src={StorageServer}
              alt="Storage Servers"
              loading="lazy"
            />
            <div className={styles.cardHeaderContainer}>
              <h2 className={styles.cardHeader}>Storage Servers</h2>
              <p className={styles.cardSubHeader}>Instant Provision</p>
            </div>
          </div>
          <p className={styles.cardText}>
            Scalable, secure, high-capacity storage solutions for managing
            data, backups, and media storage.
          </p>
          <p className={styles.cardLearnMore}>Learn More</p>
        </motion.div>
      </a>

      {/* GPU-Enabled Servers Card */}
      <a href="/gpu-enabled-servers" aria-label="Learn more about GPU-Enabled Servers">
        <motion.div className={styles.card} variants={cardHover} whileHover="hover">
          <div className={styles.cardTopRow}>
            <img
              className={styles.image}
              src={AI_Capable_Server}
              alt="GPU-Enabled Servers"
              loading="lazy"
            />
            <div className={styles.cardHeaderContainer}>
              <h2 className={styles.cardHeader}>GPU Enabled Servers</h2>
              <p className={styles.cardSubHeader}>Instant Provision</p>
            </div>
          </div>
          <p className={styles.cardText}>
            Accelerate intensive tasks with GPU-enabled servers, optimized
            for AI, machine learning, rendering, and scientific
            computations.
          </p>
          <p className={styles.cardLearnMore}>Learn More</p>
        </motion.div>
      </a>
    </div>
  </div>
</section>

  );
};

export default OurServicesComponent;
